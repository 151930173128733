import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const BoxButtonConfirm = styled.div`
    width: 100%;
    margin: 30px 0 30px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const ParagModalConfirme = styled.p`
    width: 100%;
    text-align: center;
    color: ${theme.color.colorParagraphe};
    font-size: ${theme.font.medium};
`;

export const SpanModalConfirme = styled.p`
    margin-top: 10px;
    width: 100%;
    text-align: center;
    color: ${theme.color.alert};
    font-size: ${theme.font.medium};
`;