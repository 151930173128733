import { AddAssuranceClass } from "../../../../shared/class/assurance/addAssurance/AddAssurance";
import { AddAssuranceError } from "./AddAssuranceError";
import { AddAssuranceSchema, ErreurAddAssuranceType } from "./addAssuranceValidationType";

export const addAssuranceValidation = (data: AddAssuranceClass): ErreurAddAssuranceType => {

    const erreurPage = new AddAssuranceError();

    const erreurUpdateAssuranceFinal: ErreurAddAssuranceType = {
        error: false,
        detail: erreurPage
    };

    const schemaValidation = AddAssuranceSchema.safeParse(data);

    if (!schemaValidation.success) {
        Object.assign(erreurUpdateAssuranceFinal, { error: true });

        schemaValidation.error.issues.forEach(issue => {
            Object.assign(erreurPage, { [issue.path[0]]: issue.message });
        });

        return erreurUpdateAssuranceFinal;
    };

    return erreurUpdateAssuranceFinal;
};