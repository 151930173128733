import { regexEmail } from "../../../../shared/regex/regex";
import { AddAssuranceError } from "./AddAssuranceError";
import { z } from "zod";

const messageStr = { invalid_type_error: 'Le format est incorrect' };

export const AddAssuranceSchema = z.object({
    nomSociete: z.string(messageStr).min(1, { message: "Le nom de société est obligatoire" }),
    service: z.string(messageStr),
    adresse: z.string(messageStr).min(1, { message: "L'adresse est obligatoire" }),
    complementAdresse: z.string(messageStr),
    ville: z.string(messageStr).min(1, { message: "La ville est obligatoire" }),
    codePostal: z.string(messageStr).min(1, { message: "Le code postal est obligatoire" }),
    email: z.string(messageStr).regex(regexEmail, { message: 'Le format de l\'adresse e-mail est invalide' }),
});

export type ErreurAddAssuranceType = {
    error: boolean,
    detail: AddAssuranceError
};