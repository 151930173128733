import { BoxSelectCustom, BoxSelectLabelTooltips, LabelSelect, Select } from "./SelectCustomStyle";
import Tooltips from "../tooltips/Tooltips";
import { SelectProps } from "./SelectCustomType";

const SelectCustom = ({ label, id, value, options, contentTooltip, onChange, disabled, invalid }: SelectProps) => {
    return (
        <BoxSelectCustom>
            {label &&
                <BoxSelectLabelTooltips>
                    <LabelSelect htmlFor={id}>{label}</LabelSelect>
                    {contentTooltip && <Tooltips id={id + id} content={contentTooltip} />}
                </BoxSelectLabelTooltips>
            }
            <Select
                id={id}
                name={id}
                value={value}
                onChange={onChange}
                disabled={disabled}
                $invalid={invalid}
            >
                {options.map((opt, index) => (
                    <option key={index} value={opt.value}>{opt.label}</option>
                ))}
            </Select>
        </BoxSelectCustom>
    );
};

export default SelectCustom;