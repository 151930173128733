import { styled } from "styled-components";
import { theme } from "../../../../../../theme/theme";

export const BoxBtnAction = styled.div`
    width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    border-top: solid 1px ${theme.color.headerTable};
`;

export const SeparatoBtnAction = styled.div`
    width: 20px;
`;