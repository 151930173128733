import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const MainCustom = styled.main`
    width: 100vw;
    min-height: 100vh;
    margin-bottom: 50px;
    background-color: ${theme.color.secondary};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `;