
import { z } from "zod";
import { AddProduitError } from "./AddProduitError";
import { messageStr, regexPrice, regexQuantity, regexTax } from "../../../../shared/regex/regex";

export const AddProduitSchema = z.object({
    name: z.string(messageStr).min(1, {message: "Le nom est obligatoire"}),
    tax: z.string(messageStr).regex(regexTax, { message: 'Le taux de TVA est incorrecte exemple: 20.5' }),
    code: z.string(messageStr).min(1, {message: "Le code est obligatoire"}),
    totalPriceHt: z.string().regex(regexPrice, { message: 'Le tarif HT est incorrecte exemple: 20.50' }),
    quantity: z.string().regex(regexQuantity, { message: 'La quantité est incorrecte exemple: 3' }),
    invoiceIntegration: z.boolean()
});

export type AddProduitType = z.infer<typeof AddProduitSchema>;

export type ErreurAddProduitType = {
    error: boolean,
    detail: AddProduitError
};

export type ModaleAjouterProduitProps = {
    setProduits: Function
};