import { styled } from 'styled-components';
import { theme } from '../../../../theme/theme';

export const BoxTabsAddProduit = styled.div`
    width: 100%; 
    background-color: ${theme.color.white};
    height: 40px; 
    display: flex; 
    flex-direction: row; 
    align-items: center;
`;

export const FormVoirAddProduit = styled.form`
    width: 1150px;
    height: fit-content; 
    max-height: 90vh; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 25px;
    @media screen and (${theme.screen.tablette}) {
        width: 90vw;
    };
`;

export const BoxTabAddProduit = styled.div`
    width: 100%; 
    min-height: fit-content; 
    padding: 15px 25px; 
    background-color: #f4f4f4;
`;

export const BoxFieldTabAddProduit = styled.div`
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (${theme.screen.tablette}) {
       &:first-child {
            margin-bottom: 50px;
       }
    };
`;





export const BoxTypeAddProduit = styled.div`
    width: 100%;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const SeparatorSelectAddProduit = styled.div`
    width: 20px;
`;

export const BoxNomEmailAddProduit = styled.div`
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (${theme.screen.tablette}) {
       &:first-child {
            margin-bottom: 50px;
       }
    };
`;

export const BoxBtnModifierAddProduit = styled.div`
    width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    border-top: solid 1px ${theme.color.headerTable};
`;

export const FormVoirAddProduitHeading = styled.h3`
    font-size: ${theme.font.medium};
    color:  ${theme.color.primary};
`;

export const BoxBtnActionAddProduit = styled.div`
    width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    border-top: solid 1px ${theme.color.headerTable};
`;

export const SeparatoBtnActionAddProduit = styled.div`
    width: 20px;
`;