import styled from "styled-components";

export const Container = styled.div`
    z-index: 2;
    width: fit-content; 
    height: fit-content; 
    position: fixed; 
    top: 30px; 
    right: 30px; 
    display: flex; 
    flex-direction: column; 
    align-items: flex-end;
`;