import { fileSchema } from "../../../../../types/File";
import { FileError } from "./FileError";
import { ErreurFileUserType } from "./FilesValidationType";


export const filesValidation = (cachet: File | undefined, kbis: File | undefined, rib: File | undefined, cni: File | undefined): ErreurFileUserType => {

    const erreurFiles = new FileError();
    
    const erreurAddUserFinal: ErreurFileUserType = {
        error: false,
        detail: erreurFiles
    };
    
    const cachetSchemaValidation = fileSchema.safeParse(cachet);
    if(!cachetSchemaValidation.success){
        Object.assign(erreurAddUserFinal, { error: true });
        Object.assign(erreurFiles, { cachet: true });
    };

    const kbisSchemaValidation = fileSchema.safeParse(kbis);
    if(!kbisSchemaValidation.success){
        Object.assign(erreurAddUserFinal, { error: true });
        Object.assign(erreurFiles, { kbis: true });
    };

    const ribSchemaValidation = fileSchema.safeParse(rib);
    if(!ribSchemaValidation.success){
        Object.assign(erreurAddUserFinal, { error: true });
        Object.assign(erreurFiles, { rib: true });
    };

    const cniSchemaValidation = fileSchema.safeParse(cni);
    if(!cniSchemaValidation.success){
        Object.assign(erreurAddUserFinal, { error: true });
        Object.assign(erreurFiles, { cni: true });
    };

    return erreurAddUserFinal;
};