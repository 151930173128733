import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/authContext/AuthContext";
import { useToasts } from "../../../contexts/toastContext/toastContext";
import { useTitlePage } from "../../../hooks/useTitlePage";
import { text } from "../../../shared/text/text";
import { catchError } from "../../../core/request/catchError";
import BasePage from "../../../components/common/basePage/BasePage";
import { BoxHeadingListeAssurance, BoxListeAssurance, HeadingListeAssurance } from "./ListeAssuranceStyle";
import { AssuranceApiType } from "./ListeAssuranceType";
import TableauAssurance from "../../../components/feature/assurance/listeAssurance/TableauAssurance";
import ModaleAjouterAssurance from "../../../components/feature/assurance/modaleAjouterAssurance/ModaleAjouterAssurance";

const ListeAssurance = (): JSX.Element => {
    const { token } = useAuth().isAuthenticated;
    const { pushToast } = useToasts();
    const titlePage = useTitlePage(text.pages.listeUtilisateur.title);

    const [assurances, setAssurances] = useState<AssuranceApiType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!token) return;
        setLoading(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/assurance/coordonnee`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {

                catchError(data);

                setAssurances(() => data);
            })
            .catch((error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setLoading(() => false);
            });
    }, [pushToast, token]);

    return (
        <BasePage title={titlePage}>
            <BoxListeAssurance>
                <BoxHeadingListeAssurance>
                    <HeadingListeAssurance>{text.pages.listeAssurance.title}</HeadingListeAssurance>

                    <ModaleAjouterAssurance setAssurances={setAssurances} />
                </BoxHeadingListeAssurance>

                <TableauAssurance
                    assurances={assurances}
                    setAssurances={setAssurances}
                    loading={loading}
                />
            </BoxListeAssurance>
        </BasePage >
    );
};

export default ListeAssurance;