import { createGlobalStyle } from "styled-components";
import { theme } from "../theme/theme";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Audiowide;
    src: url(${require("./fonts/Audiowide.ttf")});
  }

  @font-face {
    font-family: Montserrat-Regular;
    src: url(${require("./fonts/Montserrat-Regular.ttf")});
  }

  @font-face {
    font-family: Montserrat-Bold;
    src: url(${require("./fonts/Montserrat-Bold.ttf")});
  }

  @font-face {
    font-family: Montserrat-Italic;
    src: url(${require("./fonts/Montserrat-Italic.ttf")});
  }

  @font-face {
    font-family: Roboto-Regular;
    src: url(${require("./fonts/Roboto-Regular.ttf")});
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: Montserrat-Regular, sans-serif;
    font-size: ${theme.font.regular};
  }

  body {
    max-width: 100vw;
    min-height: 100vh;
    background-color: ${theme.color.secondary};
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export default GlobalStyle;