import jwtDecode from 'jwt-decode';
import { z } from 'zod';

export const DecodedTokenSchema = z.object({
    iat: z.number(),
    exp: z.number(),
    roles: z.string().array(),
    id: z.number(),
    username: z.string(),
    nom: z.string(),
    prenom: z.string(),
    emailConfirme: z.boolean()
});

export type DecodedToken = z.infer<typeof DecodedTokenSchema>;

export type DataAuthToken = {
    connected: boolean;
    id: number | undefined;
    role: string | undefined;
    username: string | undefined;
    nom: string | undefined;
    prenom: string | undefined;
    exp: number,
    emailConfirme: boolean,
    token: string | undefined;
    refresh_token: string | undefined;
};

export const validStorage = (token: string | null, refreshToken: string | null): DataAuthToken => {

    if (token !== null && refreshToken !== null) {
        try {
            const decodeToken: DecodedToken = jwtDecode(token);

            const dataUser = {
                connected: true,
                id: decodeToken.id,
                username: decodeToken.username,
                nom: decodeToken.nom,
                prenom: decodeToken.prenom,
                role: decodeToken.roles[0],
                exp: decodeToken.exp,
                emailConfirme: decodeToken.emailConfirme,
                token: token,
                refresh_token: refreshToken
            };

            return dataUser;
        } catch (error) {
            const dataUser = {
                connected: false,
                id: undefined,
                username: undefined,
                nom: undefined,
                prenom: undefined,
                role: undefined,
                exp: 0,
                emailConfirme: false,
                token: undefined,
                refresh_token: undefined
            };

            return dataUser;
        };
    } else {
        const dataUser = {
            connected: false,
            id: undefined,
            username: undefined,
            nom: undefined,
            prenom: undefined,
            role: undefined,
            exp: 0,
            emailConfirme: false,
            token: undefined,
            refresh_token: undefined
        };

        return dataUser;
    };
};
