import { useState } from 'react';
import { LigneAssurancePropsType } from './LigneAssuranceType';
import { useAuth } from '../../../../../contexts/authContext/AuthContext';
import { useToasts } from '../../../../../contexts/toastContext/toastContext';
import { catchError } from '../../../../../core/request/catchError';
import { AssuranceApiType, AssuranceSchema } from '../../../../../pages/assurance/listeAssurance/ListeAssuranceType';
import { CelluleActionLigneTable, CelluleId, CelluleLigneTable, LigneTableContainer } from '../../../../common/tableau/container/LigneTableContainer';
import { templateGridAssurance } from '../TableauAssurance';
import { IconTrashCustom } from '../../../../common/tableau/IconsTable/IconsTable';
import ModalConfirmation from '../../../../common/modalConfirmation/ModalConfirmation';
import { text } from '../../../../../shared/text/text';
import ModaleAssuance from '../../modaleAssurance/ModaleAssurance';

const LigneUtilisateur = ({ assurance, setAssurances }: LigneAssurancePropsType): JSX.Element | null => {
    const { token } = useAuth().isAuthenticated;
    const { pushToast } = useToasts();

    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);

    const supprimerUneAssurance = async (id: number) => {

        if (!token) return;

        setLoadingConfirm(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/assurance/coordonnee/${id}`, {
            method: "DELETE",
            headers: { "Authorization": `Bearer ${token}` },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                catchError(data);

                pushToast({ content: data.detail, type: "success", duration: 5 });
                setAssurances((curr: AssuranceApiType[]) => curr.filter(assurance => assurance.id !== id));
                setOpenConfirm((curr: boolean) => !curr);
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: "alert", duration: 5 });
            })
            .finally(() => {
                setLoadingConfirm(() => false);
            });
    };

    if (assurance && AssuranceSchema.safeParse(assurance).success) {
        return (
            <LigneTableContainer $templateGrid={templateGridAssurance}>
                <CelluleLigneTable $lineName='id'><CelluleId>{assurance.id}</CelluleId></CelluleLigneTable>
                <CelluleLigneTable $lineName='email'>{assurance.email}</CelluleLigneTable>
                <CelluleLigneTable $lineName='nomSociete'>{assurance.nomSociete}</CelluleLigneTable>
                <CelluleLigneTable $lineName='ville'>{assurance.ville}</CelluleLigneTable>

                <CelluleActionLigneTable $lineName='action'>

                    <ModaleAssuance
                        setAssurances={setAssurances}
                        assurance={assurance}
                    />

                    {/*<ModalEnvoyerUnEmail
                        user={assurance}
                    />*/}

                    <IconTrashCustom
                        onClick={() => setOpenConfirm((curr: boolean) => !curr)}
                    />
                </CelluleActionLigneTable>

                <ModalConfirmation
                    label={text.components.ligneUtilisateur.labelModalConfirm}
                    complementLabel={assurance.nomSociete}
                    open={openConfirm}
                    setOpen={setOpenConfirm}
                    action={() => supprimerUneAssurance(assurance.id)}
                    loading={loadingConfirm}
                />
            </LigneTableContainer>
        );
    } else {
        return null;
    };
};

export default LigneUtilisateur;