
import { AssuranceApiType, AssuranceSchema } from "../../../../pages/assurance/listeAssurance/ListeAssuranceType";
import { UpdateAssuranceError } from "./UpdateAssuranceError";
import { ErreurUpdateAssuranceType } from "./updateAssuranceValidationType";

export const updateAssuranceValidation = (data: AssuranceApiType): ErreurUpdateAssuranceType => {

    const erreurPage = new UpdateAssuranceError();

    const erreurUpdateAssuranceFinal: ErreurUpdateAssuranceType = {
        error: false,
        detail: erreurPage
    };

    const schemaValidation = AssuranceSchema.safeParse(data);

    if (!schemaValidation.success) {
        Object.assign(erreurUpdateAssuranceFinal, { error: true });

        schemaValidation.error.issues.forEach(issue => {
            Object.assign(erreurPage, { [issue.path[0]]: issue.message });
        });

        return erreurUpdateAssuranceFinal;
    };

    return erreurUpdateAssuranceFinal;
};