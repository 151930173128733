import { styled } from "styled-components";
import { theme } from "../../../../../../../theme/theme";
import { ROLE_ADMIN } from "../../../../../../../shared/constants/Constatnts";

type TabActionStyleProps = {
    $role: string | undefined
};

export const BoxBtnAction = styled.div<TabActionStyleProps>`
    width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.$role !== ROLE_ADMIN ? `flex-end` : `space-between`};
    border-top: solid 1px ${theme.color.headerTable};
`;

export const BoxBtnCancelAndValide = styled.div`
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const SeparatoBtnAction = styled.div`
    width: 20px;
`;