import { styled } from "styled-components";
import { theme } from "../../../theme/theme";
import { LogoStyleProps } from "./LogoType";

export const BoxLogo = styled.div<LogoStyleProps>`
    width: ${props => props.$visible ? `fit-content` : `0`}; 
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`;

export const H1 = styled.h1<LogoStyleProps>`
    height: 40px;
    color: white;
    font-size: 40px;
    letter-spacing: 1px;
    font-family: Montserrat-Bold;
    color: ${theme.color.primary};
`;

export const FirstLetter = styled.span<LogoStyleProps>`
    color: ${theme.color.letterGray};
    height: fit-content;
    font-family: Audiowide;
    font-size: 40px;
`;

export const SecondSubtititle = styled.span<LogoStyleProps>`
    height: 16px;
    color: ${theme.color.letterGray}; 
    font-family: Montserrat-Bold;
    font-size: ${theme.font.regular};
`;

export const BoxLogoVide = styled.div`
    height: 60px;
`;

export const FirstLetterMin = styled.span`
    color: ${theme.color.letterGray};
    height: fit-content;
    font-family: Audiowide;
    font-size: 20px;
`;

export const TwoLetterMin= styled.span`
    color: ${theme.color.primary};
    height: fit-content;
    font-family: Audiowide;
    font-size: 15px;
`;