import { createContext, useCallback, useContext, useState } from "react";
import { Auth, AuthProviderType } from "./AuthContextType";
import { hasAuthenticated } from "../../services/authApi";
import { NAME_STORAGE, NAME_STORAGE_REFRESH } from "../../shared/constants/Constatnts";
import { DataAuthToken } from "../../services/validStorage";
import ModalSession from "../../components/common/modalSession/ModalSession";

export const AuthContext = createContext<Auth>({
    isAuthenticated: {
        connected: false,
        id: undefined,
        username: undefined,
        nom: undefined,
        prenom: undefined,
        role: undefined,
        exp: 0,
        emailConfirme: false,
        token: undefined,
        refresh_token: undefined
    },
    setIsAuthenticated: () => { },
    logout: () => { }
});

export const useAuth = () => {
    return useContext<Auth>(AuthContext);
};

const AuthProvider = ({ children }: AuthProviderType) => {

    const [isAuthenticated, setIsAuthenticated] = useState<DataAuthToken>(hasAuthenticated());


    const logout = useCallback(() => {
        sessionStorage.removeItem(NAME_STORAGE);
        sessionStorage.removeItem(NAME_STORAGE_REFRESH);

        setIsAuthenticated(hasAuthenticated());
    }, []);

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated,
            logout
        }}>
            <ModalSession/>
            
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;