import { Navigate } from 'react-router-dom';
import {  useAuth } from "../../../contexts/authContext/AuthContext";

const AuthRoute = ({ Component, roles }: {Component: any, roles: string[]}) => {

    const {connected, role} = useAuth().isAuthenticated;

    if(!connected || !role){
       return <Navigate to = '/login' />
    };

    return (connected === true && role && roles.includes(role)) ? <Component /> : <Navigate to = '/' />
};

export default AuthRoute;
