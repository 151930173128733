export class FileError {
    cni: boolean;
    kbis: boolean;
    cachet: boolean;
    rib: boolean;
    constructor() {
        this.cni = false;
        this.kbis = false;
        this.cachet = false;
        this.rib = false;
    };
};