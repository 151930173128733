import { styled } from "styled-components";
import { CheckboxstylePropsType } from "./CheckboxType";
import { theme } from "../../../theme/theme";

export const BoxCheckbox = styled.div<CheckboxstylePropsType>`
    width: ${props => props.$width ? `${props.$width}` : `fit-content`};
    margin-bottom: ${props => props.$mb ? `${props.$mb}px` : `15px`};
    display: flex;
    ${props => props.$reverse === true ? `flex-direction: row-reverse;` : `flex-direction: row;`}
    align-items: flex-start;
`;

export const Label = styled.label`
    font-size: 16px;
    color: ${theme.color.letterGray};
    margin-bottom: 5px;
    white-space: nowrap;
`;

export const InputCheckbox = styled.input<CheckboxstylePropsType>`
    width: 20px;
    height: 20px;
    border: solid 1px #dadada;
    ${props => props.$reverse === true ? ` margin-right: 10px;` : ` margin-left: 10px;`}
    accent-color: ${theme.color.primary};
`;