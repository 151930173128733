import { z } from "zod";

export const DecodedTokenSchema = z.object({
  exp: z.number(),
  iat: z.number(),
  id: z.number(),
  nom: z.string(),
  roles: z.string().array(),
  username: z.string()
});
export type DecodedTokenType = z.infer<typeof DecodedTokenSchema>;

export const LoginBodySchema = z.object({
  email: z.string().email(),
  password: z.string()
});

export const LoginResponseSchema = z.object({
  token: z.string(),
  refresh_token: z.string()
});

export type LoginBody = z.infer<typeof LoginBodySchema>;
export type LoginResponse = z.infer<typeof LoginResponseSchema>;