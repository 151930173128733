export const optionTax = [
    { value: "20", label: "20" },
    { value: "10", label: "10" },
    { value: "8.5", label: "8.5" },
    { value: "7.7", label: "7.7" },
    { value: "5", label: "5" },
    { value: "2.1", label: "2.1" }
];

export const optionIntegration = [
    { value: 'true', label: "oui" },
    { value: 'false', label: "non" },
];