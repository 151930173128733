import { useState } from 'react';
import { BoxIconBurger, BoxLinkDrawer, ContainerNavMainDrawer, HeadMainDrawer, HeaderDrawer, IconBurger, IconListAssuance, IconListProduct, IconListUser, IconLogout, LinkCustom, MainDrawer, NavDrawer, SeparatorLink, SpanLinkCustom } from './DrawerStyle';
import { useAuth } from '../../../../contexts/authContext/AuthContext';
import { ChildrenType } from './DrawerType';
import Logo from '../../logo/Logo';
import { text } from '../../../../shared/text/text';
import { ROLE_ADMIN, ROLE_GESTIONNAIRE } from '../../../../shared/constants/Constatnts';

const Drawer = ({ children }: ChildrenType): JSX.Element => {

    const { logout } = useAuth();
    const { role } = useAuth().isAuthenticated;
    const [open, setOpen] = useState<boolean>(false);

    return (
        <ContainerNavMainDrawer>
            <HeaderDrawer>
                <IconLogout onClick={logout} />
            </HeaderDrawer>

            <NavDrawer $open={open}>
                <BoxIconBurger>
                    <IconBurger onClick={() => setOpen(!open)} />
                </BoxIconBurger>

                <BoxLinkDrawer $open={open}>
                    <Logo visible={open} />

                    <SeparatorLink />

                    {(role === ROLE_ADMIN || role === ROLE_GESTIONNAIRE) &&
                        <LinkCustom to={'/'}>
                            <IconListUser $open={open} title={text.components.drawer.titleIconUtilisateur} />
                            <SpanLinkCustom $open={open}>{text.components.drawer.lienListeUtilisateur}</SpanLinkCustom>
                        </LinkCustom>
                    }

                    <SeparatorLink />

                    {role === ROLE_ADMIN &&
                        <LinkCustom to={'/produit'}>
                            <IconListProduct $open={open} title={text.components.drawer.titleIconProduit} />
                            <SpanLinkCustom $open={open}>{text.components.drawer.lienListeProduit}</SpanLinkCustom>
                        </LinkCustom>
                    }

                    <SeparatorLink />

                    {role === ROLE_ADMIN &&
                        <LinkCustom to={'/assurance'}>
                            <IconListAssuance $open={open} title={text.components.drawer.titleIconAssurance} />
                            <SpanLinkCustom $open={open}>{text.components.drawer.lienListeAssurance}</SpanLinkCustom>
                        </LinkCustom>
                    }
                </BoxLinkDrawer>
            </NavDrawer >

            <MainDrawer $open={open}>
                <HeadMainDrawer />

                {children}
            </MainDrawer>
        </ContainerNavMainDrawer>
    );
};

export default Drawer;