import { styled } from "styled-components";
import { DrawerStylePropsType } from "./DrawerType";
import { Link } from "react-router-dom";
import { BoxArrowRight, List, PersonFill, BagFill , CardChecklist} from "react-bootstrap-icons";
import { theme } from "../../../../theme/theme";

export const HeaderDrawer = styled.div`
    z-index: 0;
    width: 100vw;
    height: 50px;
    position: fixed;
    padding: 0 40px 0 20px;
    top: 0;
    left: 0;
    background-color: ${theme.color.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

export const ContainerNavMainDrawer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
`;

export const NavDrawer = styled.nav<DrawerStylePropsType>`
     z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width:  ${props => props.$open ? `270px` : `50px`};
    height: 100vh;
    background-color: ${theme.color.bgcDrawer};
    transition: width 0.3s;
    display: flex;
    flex-direction: column;
    align-items: flex-sart;
    justify-content: flex-start;
    color: ${theme.color.white};
`;

export const SeparatorLink = styled.div`
    width: 100%; 
    height: 30px;
`;

export const LinkCustom = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: opacity 0.2s, scale 0.2s;
    &:hover{
        cursor: pointer;
        opacity: 0.6;
    }
    &:active{
        cursor: pointer;
        scale: 0.95;
}
`;

export const SpanLinkCustom = styled.span<DrawerStylePropsType>`
    ${props => props.$open ? `opacity: 1;` : `opacity: 0;`}
    ${props => props.$open ? `width: fit-content;` : `width: 0;`}
    ${props => props.$open ? `margin-left: 50px;` : `margin-left: 0;`}
    white-space: nowrap;
    font-size: 1.2em;
    margin-top: 4px;
    transition: all 0.3s;
`;

export const MainDrawer = styled.div<DrawerStylePropsType>`
    flex: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const HeadMainDrawer = styled.div`
    width: 100%;
    height: 60px;
`;

export const BoxIconBurger = styled.div`
    width: 100%;
    padding: 10px 10px 0 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
`;

export const IconBurger = styled(List)`
    font-size: ${theme.font.xxlarge};
    color: ${theme.color.white};
    &:hover {
        cursor: pointer;
        opacity: 0.6;
    }
    &:active {
        scale: 0.7;
    }
`;

export const BoxLinkDrawer = styled.div<DrawerStylePropsType>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ${props => props.$open ? `0 30px 30px 30px` : `0 0 0 10px`}
`;

export const IconListUser = styled(PersonFill)<DrawerStylePropsType>`
    font-size: ${theme.font.xxlarge};
    color: ${props => props.$open ? theme.color.white : theme.color.primary};
    position: fixed;
`;

export const IconListProduct = styled(BagFill)<DrawerStylePropsType>`
    font-size: ${theme.font.xxlarge};
    color: ${props => props.$open ? theme.color.white : theme.color.primary};
    position: fixed;
`;

export const IconListAssuance = styled(CardChecklist)<DrawerStylePropsType>`
    font-size: ${theme.font.xxlarge};
    color: ${props => props.$open ? theme.color.white : theme.color.primary};
    position: fixed;
`;

export const IconLogout = styled(BoxArrowRight)`
    font-size: ${theme.font.xxlarge};
    color: ${theme.color.primary};
    position: fixed;
    transition: opacity 0.2s, scale 0.2s;
    &:hover {
        cursor: pointer;
        opacity: 0.6;
    }
    &:active {
        scale: 0.7;
    }
`;