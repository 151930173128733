import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";
import { BoxPaginationTableUtilisateur, BoxRightTableUtilisateur, BoxleftTableUtilisateur, BtnIconArrow } from "./PaginationStyle";
import SelectElementParPage from "./selectElementParPage/SelectElementParPage";
import { PaginationTypeProps } from "./PaginationType";

const Pagination = ({ page, totalpages, elementParPage, setElementParPage, onClickChangePage }: PaginationTypeProps): JSX.Element | null => {
    if (totalpages > 0) {
        return (
            <BoxPaginationTableUtilisateur>
                <BoxleftTableUtilisateur>

                    <p>élements par page</p>

                    <div style={{ width: 20 }}></div>

                    <SelectElementParPage
                        id="elementParPage"
                        value={elementParPage}
                        onChange={(e) => setElementParPage(e.target.value)}
                    />
                </BoxleftTableUtilisateur>

                <BoxRightTableUtilisateur>

                    <BtnIconArrow
                        type="button"
                        disabled={page === 0}
                        onClick={() => onClickChangePage(page - 1)}
                    >
                        <CaretLeftFill size={15} />
                    </BtnIconArrow>

                    <span>Page {page + 1} / {totalpages}</span>

                    <BtnIconArrow
                        type="button"
                        disabled={(page + 1) === totalpages}
                        onClick={() => onClickChangePage(page + 1)}
                    >
                        <CaretRightFill size={15} />
                    </BtnIconArrow>

                </BoxRightTableUtilisateur>
            </BoxPaginationTableUtilisateur>
        );
    } else {
        return null;
    };
};

export default Pagination; 