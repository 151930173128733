import { useState } from 'react';
import { TableauUtilisateurPropsType } from './TableauUtilisateurType';
import LoaderTable from '../../../../common/loaderTable/LoaderTable';
import { TableUserSchema } from '../../../../../types/UserApiType';
import InputSearch from '../../../../common/inputSearch/InputSearch';
import LigneUtilisateur from './ligneUtilisateur/LigneUtilisateur';
import Pagination from '../../../../common/tableau/pagination/Pagination';
import { BoxTable, CelluleTableHead, TableBody, TableContainer, TableHead } from '../../../../common/tableau/container/TableContainer';

const theadData = [
    { label: 'Id', lineName: 'id' },
    { label: 'E-mail', lineName: 'email' },
    { label: 'Nom de société', lineName: 'nomSociete' },
    { label: 'Nom du dirigeant', lineName: 'nomDirigeant' },
    { label: 'Date création', lineName: 'dateCreation' },
    { label: 'Actif', lineName: 'actif' },
    { label: 'Action', lineName: 'action' }
];

export const templateGridUtilisateur ='[id] 0.2fr [email] 1fr [nomSociete] 1fr [nomDirigeant] 1fr [dateCreation] 1fr [actif] 0.3fr [action] 0.5fr'

const TableauUtilisateur = ({ users, setUsers, loading = false }: TableauUtilisateurPropsType): JSX.Element | null => {
    const [fieldSearch, setFieldSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    if (loading) return <LoaderTable isLoading={loading} />;

    if (users && users.length && TableUserSchema.safeParse(users).success) {
        return (
            <TableContainer>
                <InputSearch
                    width={100}
                    placeholder="Rechercher..."
                    id="rechercheUser"
                    type="text"
                    value={fieldSearch}
                    onChange={(e) => setFieldSearch(e.target.value)}
                />

                <BoxTable>
                    <TableHead $templateGrid={templateGridUtilisateur}>
                        {theadData.map((head, index) => (
                            <CelluleTableHead
                                $lineName={head.lineName}
                                key={head.label + index}
                            >
                                {head.label}
                            </CelluleTableHead>
                        ))}
                    </TableHead>

                    <TableBody>
                        {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((val) => {
                            return val.nomSociete.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase()) ||
                                val.email.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase()) ||
                                val.nomDirigeant.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase()) ||
                                val.createdAt.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase())
                        }).map((user, index) => (
                            <LigneUtilisateur key={index} user={user} setUsers={setUsers} />
                        ))}
                    </TableBody>
                </BoxTable>

                <Pagination
                    elementParPage={rowsPerPage}
                    setElementParPage={setRowsPerPage}
                    page={page}
                    totalpages={Math.ceil(users.length / rowsPerPage)}
                    onClickChangePage={handleChangePage}
                />
            </TableContainer>
        );
    } else {
        return null;
    }
};

export default TableauUtilisateur;