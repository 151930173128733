import { styled } from "styled-components";
import { theme } from "../../../theme/theme";

type SelectStyleProps = {
    $invalid?: string
};

export const BoxSelectCustom = styled.div`
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const Select = styled.select<SelectStyleProps>`
    width: 100%;
    height: 40px;
    border: solid 1px ${theme.color.grayBorder};
    border-radius: ${theme.css.radius};
    background-color: ${theme.color.white};
    padding: 0 10px;
    font-family: Montserrat-Regular;
    font-size: ${theme.font.regular};
    outline: none;
    ${props => props.$invalid !== '' && `border: solid 1px ${theme.color.alert};`}
    &option{
        font-family: "Montserrat-Regular", sans-serif;
        font-size: ${theme.font.regular};
        color: ${theme.color.letterGray};
    }
    &: focus{
        border: solid 1px ${theme.color.primary};
    }
`;

export const LabelSelect = styled.label`
    color: ${theme.color.primary}
`;

export const BoxSelectLabelTooltips = styled.div`
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px; 
`;
