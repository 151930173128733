import { createPortal } from "react-dom";
import { BoxHeadingPortal, CircleClosePortal, ModalBody, PortalTitle, Wrapper } from "./PortalStyle";
import { PortalProps } from "./PortalType";

const Portal = ({ open, onClick, title, displayHead, width, height, fontSize, children }: PortalProps): JSX.Element | null => {
    if (open && document) {
        return createPortal(
            <Wrapper>
                <ModalBody $width={width} $height={height}>
                    {displayHead && <BoxHeadingPortal>
                        <PortalTitle $fontSize={fontSize}>{title}</PortalTitle>
                        <CircleClosePortal onClick={onClick} />
                    </BoxHeadingPortal>}
                    <div style={{overflow: 'auto', padding: '0 25px'}}>
                        {children}
                    </div>
                </ModalBody>
            </Wrapper>
            , document.body);
    } else {
        return null;
    };
};

export default Portal;


