
import { AddUserClass } from "../../../../shared/class/utilisateur/addUser/AddUserClass";
import { AddUserError } from "./AddUserError";
import { AddUserSchema, ErreurAddUserType } from "./addUserValidationType";

export const addUserValidation = (data: AddUserClass): ErreurAddUserType => {

    const erreurPage = new AddUserError();

    const erreurAddUserFinal: ErreurAddUserType = {
        error: false,
        detail: erreurPage
    };

    const schemaValidation = AddUserSchema.safeParse(data);

    if (!schemaValidation.success) {
        Object.assign(erreurAddUserFinal, { error: true });

        schemaValidation.error.issues.forEach(issue => {
            Object.assign(erreurPage, { [issue.path[0]]: issue.message });
        });

        return erreurAddUserFinal;
    };

    return erreurAddUserFinal;
};