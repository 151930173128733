export class AddProduitClass {
    name: string;
    tax: string;
    code: string;
    totalPriceHt: string;
    quantity: string;
    invoiceIntegration: boolean;

    constructor() {
        this.name = '';
        this.tax = '20';
        this.code = '';
        this.totalPriceHt = '';
        this.quantity = '1';
        this.invoiceIntegration = false;
    };
};