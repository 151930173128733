
import { z } from "zod";
import { UpdateProduitError } from "./UpdateProduitError";
import { messageNbr, messageStr } from "../../../../shared/regex/regex";

export const UpdateProduitSchema = z.object({
    id: z.number(messageNbr).min(1),
    name: z.string(messageStr).min(1, {message: "Le nom est obligatoire"}),
    tax: z.string(messageStr).min(1, {message: "La TVA est obligatoire"}),
    code: z.string(messageStr).min(1, {message: "Le code est obligatoire"}),
    totalPriceGross: z.string(messageStr).min(1, {message: "Le prix TTC est obligatoire"}),
    totalPriceHt:z.string(messageStr).min(1, {message: "Le prix HT est obligatoire"}),
    quantity:z.string(messageStr).min(1, {message: "La quantité est obligatoire"}),
    createdAt:z.string(messageStr).min(1),
    updateAt:z.string(messageStr).min(1),
    invoiceIntegration: z.boolean()
});

export type UpdateProduitType = z.infer<typeof UpdateProduitSchema>;

export type ErreurUpdateProduitType = {
    error: boolean,
    detail: UpdateProduitError
};