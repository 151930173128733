import { styled } from 'styled-components';
import { theme } from '../../../../../theme/theme';

export const BoxTabsCompteUtilisateur = styled.div`
    width: 100%; 
    background-color: ${theme.color.white};
    height: 40px; 
    display: flex; 
    flex-direction: row; 
    align-items: center;
`;

export type TabProps = {
    $indexSelected: string
}

export const TabCompteUtilisateur = styled.div<TabProps>`
    width: 150px; 
    height: 100%; 
    background-color: ${props => props.$indexSelected === props.id ? theme.color.secondary : theme.color.white};
    color:  ${props => props.$indexSelected === props.id ? theme.color.primary : theme.color.letterGray};
    text-decoration: ${props => props.$indexSelected === props.id ? 'underline' : 'none'};
    text-underline-offset: 8px;
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: center;
    transition: background-color 0.2s, color 0.2s, text-decoration 0.2s;
    &: hover {
        cursor: pointer;
        
    }
`;

export const FormVoirUtilisateur = styled.form`
    width: 1150px;
    height: fit-content; 
    max-height: 90vh; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 25px;
    @media screen and (${theme.screen.tablette}) {
        width: 90vw;
    };
`;

export const BoxTypeCompteUtilisateur = styled.div`
    width: 100%;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const SeparatorSelectUtilisateur = styled.div`
    width: 20px;
`;

export const BoxNomEmailUtilisateur = styled.div`
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (${theme.screen.tablette}) {
       &:first-child {
            margin-bottom: 50px;
       }
    };
`;

export const BoxBtnModifierUtilisateur = styled.div`
    width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    border-top: solid 1px ${theme.color.headerTable};
`;

export const FormVoirUtilisateurHeading = styled.h3`
    font-size: ${theme.font.medium};
    color:  ${theme.color.primary};
`;