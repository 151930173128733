import { styled } from "styled-components";
import { theme } from "../../../../../../theme/theme";

type InputDocProps = {
    $width?: string,
    $invalid?: boolean
};

export const BoxInputDoc = styled.div<InputDocProps>`
    width: ${props => props.$width ? `${props.$width}%` : `440px`};
    height: 78px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    & p {
        color: red; 
        font-size: 10px;    
    }
    @media screen and (${theme.screen.tablette}) {
        width: 100%;
        &:first-child{
            margin-bottom: 25px;
        }
    };
`;

export const BoxLabelDocTooltips = styled.div`
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px; 
`;

export const LabelDoc = styled.label`
    color: ${theme.color.primary}
`;

export const InputDocumentCustom = styled.input<InputDocProps>`
    width: 100%;
    color: ${theme.color.letterGray};
    padding: 7px 12px;
    background-color: ${theme.color.white};
    border-radius: 5px;
    font-size: 15px;
    border: 1px solid ${theme.color.grayBorder};
    ${props => props.$invalid && `border: solid 1px ${theme.color.alert};`}
    &::file-selector-button{
        margin-right: 8px;
        padding: 2px 12px;
        font-size: 15px;
        background-color: ${theme.color.primary};
        color: ${theme.color.white};
        border-radius: 5px;
        border: 1px solid ${theme.color.primary};
        transition: opacity 0.2s;
        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }
`;