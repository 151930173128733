import styled, { keyframes } from "styled-components";

export const BoxLoading = styled.div`
    width: 100%; 
    margin: 50px 0 0 0;
    display: flex; 
    flex-direction: row;  
    align-items: center; 
    justify-content: center;
`;

export const prixClipFix = keyframes`
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;

export const rotate = keyframes`
  100% {transform: rotate(360deg)}
`;

export const LoaderT = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  animation: ${rotate} 1s linear infinite;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 2px solid #008483;
    animation: ${prixClipFix} 2s linear infinite ;
  }
`;