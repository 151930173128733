import { BoxIconLAbel, ButtonCustom, Loader } from "./ButtonStyle";
import { ButtonPropsType } from "./ButtonType";

const Button = ({ visible = true, width, height, padding, type, color, label, loader = false, disabled, onClick }: ButtonPropsType): JSX.Element | null => {
    if (visible) {
        return (
            <ButtonCustom
                type={type}
                $width={width}
                $height={height}
                $padding={padding}
                $color={color}
                disabled={disabled}
                onClick={onClick}
                $loader={loader}
            >

                {loader ? <Loader></Loader> : <BoxIconLAbel>{label}</BoxIconLAbel>}

            </ButtonCustom>
        );
    } else {
        return null;
    };
};

export default Button;