import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const BoxButtonSession = styled.div`
    width: 100%;
    margin: 30px 0 30px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const ParagModalSession = styled.p`
    width: 100%;
    text-align: center;
    color: ${theme.color.colorParagraphe};
    font-size: ${theme.font.medium};
`;

export const CountModalSession = styled.span`
    color: ${theme.color.alert}; 
    text-decoration: underline;
    text-underline-offset: 4px;
`;