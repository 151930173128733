export const regexPassword = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*-])[A-Za-z\d!@#$%^&*-]{10,}$/);
export const regexTelephone = new RegExp('.*[0-9].*');
export const regexEmail = new RegExp('[^@]+@[^@]+.[^@]+$');
export const regexTauxHoraire = new RegExp(/^[0-9]+([.][0-9]{0,2})?/);
export const regexSiret = new RegExp(/\d{14}/g);
export const regexNumTva = new RegExp(/^[A-Za-z]{2,4}(?=.{2,12}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,2}/);  


export const regexTax = new RegExp(/^[0-9]+([.][0-9]{0,1})?/);
export const regexQuantity= new RegExp(/^[0-9]/);
export const regexPrice = new RegExp(/^[0-9]+([.][0-9]{0,2})?/);


export const messageStr = { invalid_type_error: 'Le format est incorrect chaine attendu' };
export const messageNbr = { invalid_type_error: 'Le format est incorrect nombre attendu' };