import { useEffect, useRef} from 'react';

export const useTitlePage = (title: string): string => {

    const titleRef = useRef<string>(title);

    useEffect(() => {
        document.title = titleRef.current;
    }, [title]);

    return titleRef.current;
};

