export class UpdateUserError {
    id: string;
    nomSociete: string;
    email: string;
    nomDirigeant: string;
    prenomDirigeant: string;
    creditAbonnement: string;
    creditConsoDuMois: string;
    creditConsoGlobal: string;
    createdAt: string;
    updateAt: string;
    siret: string;
    numTva: string;
    capital: string;
    telephone: string;
    dateEditionKbis: string;
    dateExpireKbis: string;
    urlVosFactures: string;
    apiKeyVosFactures: string;
    tauxHoraireHt: string;

    constructor() {
        this.id = "";
        this.nomSociete = "";
        this.email = "";
        this.nomDirigeant = "";
        this.prenomDirigeant = "";
        this.creditAbonnement = "";
        this.creditConsoDuMois = "";
        this.creditConsoGlobal = "";
        this.createdAt = "";
        this.updateAt = "";
        this.siret = "";
        this.numTva = "";
        this.capital = "";
        this.telephone = "";
        this.dateEditionKbis = "";
        this.dateExpireKbis = "";
        this.urlVosFactures = "";
        this.apiKeyVosFactures = "";
        this.tauxHoraireHt = "";
    };
};