import { ToastProps } from "./ToastType";
import { IconClose, MessageToast, ToastContent } from "./ToastStyle";

const Toast = ({ content, type = "regular", onClick}: ToastProps) => {
    return (
        <ToastContent $type={type}>
            <MessageToast>{content}</MessageToast>
            <IconClose onClick={onClick} />
        </ToastContent>
    );
};

export default Toast;