import { BoxTabsCompteUtilisateur, FormVoirUtilisateur, TabCompteUtilisateur } from './ModaleUtilisateurStyle';
import { ModaleUtilisateurProps } from './ModaleUtilisateurType';
import { ChangeEvent, MouseEvent, FormEvent, useState, useEffect } from 'react';
import TabSociete from './tabs/tabSociete/TabSociete';
import TabAbonnement from './tabs/tabAbonnement/TabAbonnement';
import { TabAction } from './tabs/tabAction/TabAction';
import { useAuth } from '../../../../../contexts/authContext/AuthContext';
import { useToasts } from '../../../../../contexts/toastContext/toastContext';
import { UserApiSchema, UserApiType } from '../../../../../types/UserApiType';
import { UpdateUserError } from '../../../../../core/validation/user/updateUserValidation/UpdateUserError';
import { text } from '../../../../../shared/text/text';
import { updateUserValidation } from '../../../../../core/validation/user/updateUserValidation/updateUserValidation';
import { catchError } from '../../../../../core/request/catchError';
import Portal from '../../../../common/portal/Portal';
import DonneeCompte from '../../CompteUtilisateur/mouvementCompte/DonneeCompte';
import { IconPenCustom } from '../../../../common/tableau/IconsTable/IconsTable';
import { ROLE_ADMIN } from '../../../../../shared/constants/Constatnts';

const ModaleUtilisateur = ({ user, setUsers }: ModaleUtilisateurProps): JSX.Element | null => {
    const { token, role } = useAuth().isAuthenticated;
    const { pushToast } = useToasts();

    const [open, setOpen] = useState<boolean>(false);
    const [tabSelected, setTabSelected] = useState<string>('0');

    const [isEdit, setIsEdit] = useState<boolean>(false);

    const [userData, setUserData] = useState<UserApiType>(user);

    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    const [erreur, setErreur] = useState<UpdateUserError>(new UpdateUserError());

    useEffect(() => {
        role === ROLE_ADMIN ? setTabSelected('0') : setTabSelected('1');
    },[role]);

    const openModale = () => {

        if (!UserApiSchema.safeParse(user).success) {
            pushToast({ content: text.components.modaleUtilisateur.msgErrDisplay, type: "alert", duration: 5 });
            return;
        };

        setUserData(() => user);
        setOpen(() => true);
    };

    const closeModale = () => {
        setIsEdit(() => false);
        setErreur(() => new UpdateUserError());
        role === ROLE_ADMIN ? setTabSelected('0') : setTabSelected('1');
        setOpen(() => false);
    };

    const changeTab = (e: MouseEvent<HTMLSpanElement>) => {
        setTabSelected(e.currentTarget.id);
    };

    const handleChangeUser = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setUserData((curr: UserApiType) => {
            if (name === "creditAbonnement" || name === "creditConsoDuMois" || name === "creditConsoGlobal") {
                return { ...curr, [name]: Number(value) }
            };

            return { ...curr, [name]: value }
        });
    };

    const modifierUnUtilisateur = async (e: FormEvent) => {
        e.preventDefault();

        const isValide = updateUserValidation(userData);
        setErreur({ ...erreur, ...isValide.detail });

        if (isValide.error) return;

        if (!token) return;

        setLoadingUpdate(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/utilisateur/${user.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(userData)
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {

                catchError(data);

                setUsers((curr: UserApiType[]) => curr.map((item) => item.id === data.id ? data : item));
                setIsEdit(() => false);
                pushToast({ content: text.components.modaleUtilisateur.msgSuccessCreate, type: "success", duration: 5 });
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: "alert", duration: 5 });
            })
            .finally(() => {
                setLoadingUpdate(() => false);
            });
    };

    const annule = (e: FormEvent) => {
        e.preventDefault();
        setIsEdit(() => false);
        setErreur(() => new UpdateUserError());
        setUserData(() => user);
    };

    return (
        <>
            <IconPenCustom onClick={openModale} />

            <Portal
                displayHead={true}
                title={text.components.modaleUtilisateur.titlePortal}
                open={open}
                height="fit-content"
                onClick={closeModale}
            >

                <DonneeCompte user={userData} />

                <BoxTabsCompteUtilisateur>
                    {role === ROLE_ADMIN && <TabCompteUtilisateur id='0' $indexSelected={tabSelected} onClick={changeTab}>Société</TabCompteUtilisateur>}
                    <TabCompteUtilisateur id='1' $indexSelected={tabSelected} onClick={changeTab}>Abonnement</TabCompteUtilisateur>
                </BoxTabsCompteUtilisateur>

                <FormVoirUtilisateur onSubmit={modifierUnUtilisateur}>
                    {role === ROLE_ADMIN &&
                        <TabSociete
                            indexSelected={tabSelected}
                            user={userData}
                            erreur={erreur}
                            isEdit={isEdit}
                            changeUser={handleChangeUser}
                        />
                    }
                    
                    <TabAbonnement
                        indexSelected={tabSelected}
                        user={userData}
                        erreur={erreur}
                        isEdit={isEdit}
                        changeUser={handleChangeUser}
                    />

                    <TabAction
                        user={user}
                        setUsers={setUsers}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        loading={loadingUpdate}
                        annule={annule}
                    />
                </FormVoirUtilisateur>
            </Portal>
        </>
    );
};

export default ModaleUtilisateur;