
import { UpdateUserError } from "./UpdateUserError";
import { ErreurUpdateUserType, UpdateUserSchema, UpdateUserType } from "./updateUserValidationType";

export const updateUserValidation = (data: UpdateUserType): ErreurUpdateUserType => {

    const erreurPage = new UpdateUserError();

    const erreurAddUserFinal: ErreurUpdateUserType = {
        error: false,
        detail: erreurPage
    };

    const schemaValidation = UpdateUserSchema.safeParse(data);

    if (!schemaValidation.success) {
        Object.assign(erreurAddUserFinal, { error: true });

        schemaValidation.error.issues.forEach(issue => {
            Object.assign(erreurPage, { [issue.path[0]]: issue.message });
        });

        return erreurAddUserFinal;
    };

    return erreurAddUserFinal;
};