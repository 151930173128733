import { NAME_STORAGE, NAME_STORAGE_REFRESH } from '../shared/constants/Constatnts';
import { validStorage } from './validStorage';

export const hasAuthenticated = () => {

    if (sessionStorage.getItem(NAME_STORAGE) !== null && sessionStorage.getItem(NAME_STORAGE_REFRESH) !== null) {

        const userAuth = validStorage(sessionStorage.getItem(NAME_STORAGE), sessionStorage.getItem(NAME_STORAGE_REFRESH));

        return userAuth;
    };

    const dataUser = {
        connected: false,
        id: undefined,
        username: undefined,
        nom: undefined,
        prenom: undefined,
        role: undefined,
        exp: 0,
        emailConfirme: false,
        decodeToken: false,
        token: undefined,
        refresh_token: undefined
    };

    return dataUser;
};
