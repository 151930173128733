import { z } from "zod";

export const AssuranceSchema = z.object({
   id: z.number(),
   nomSociete: z.string(),
   service: z.string(),
   adresse: z.string(),
   complementAdresse: z.string(),
   ville: z.string(),
   codePostal: z.string(),
   email: z.string(),
});

export const AssuranceArraySchema = AssuranceSchema.array();

export type AssuranceApiType = z.infer<typeof AssuranceSchema>;
export type AssuranceArrayApiType = z.infer<typeof AssuranceArraySchema>;


