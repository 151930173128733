import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";
import { CheckLg, EnvelopeAt, Pen, Trash, X } from "react-bootstrap-icons";

export const IconPenCustom = styled(Pen)`
    font-size: 20px;
    color: ${theme.color.success};
    transition: opacity 0.1s;
    &:hover{
        cursor: pointer;
        opacity: 0.5;
    }
`;  

export const IconEnvelopeCustom = styled(EnvelopeAt)`
    font-size: ${theme.font.large};
    color: ${theme.color.primary};
    transition: opacity 0.1s;
    &:hover{
        cursor: pointer;
        opacity: 0.5;
    }
`; 

export const IconTrashCustom = styled(Trash)`
    font-size: 20px;
    color: ${theme.color.alert};
    transition: opacity 0.1s;
    &:hover{
        cursor: pointer;
        opacity: 0.5;
    }
`; 

export const IconCheckCustom = styled(CheckLg)`
    font-size: 20px;
    color: ${theme.color.success};
`;

export const IconXCustom = styled(X)`
    font-size: 20px;
    color: ${theme.color.alert};
`;

export const IconActiveCustom = styled(CheckLg)`
    font-size: 20px;
    color: ${theme.color.success};
`;

export const IconCancelCustom = styled(X)`
    font-size: 20px;
    color: ${theme.color.alert};
`;