import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";

export type TableStyleProps = {
    $width?: string,
    $templateGrid?: string,
    $lineName?: string,
};

export const TableContainer = styled.div`
    width: 100%; 
`;

export const BoxTable = styled.div`
    width: 100%; 
    display: flex; 
    flex-direction: column;  
    align-items: center; 
`;

export const TableHead = styled.div<TableStyleProps>`
    width: 100%; 
    padding: 15px 0;
    display: grid;
    grid-template-columns: ${props=> props.$templateGrid};
    background-color: ${theme.color.headerTable}; 
    font-weight: 600;
`;

export const TableBody = styled.div`
    width: 100%; 
`;

export const CelluleTableHead = styled.div<TableStyleProps>`
    grid-column-start: ${props => props.$lineName};
    text-align: center; 
    color: ${theme.color.primary};
    font-size: ${theme.font.small};
`;