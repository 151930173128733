import { useEffect, useState } from "react";
import { BoxHeadingListeUtilisateur, BoxListeUtilisateur, HeadingListeUtilisateur } from "./ListeUtilisateurStyle";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/authContext/AuthContext";
import { useToasts } from "../../../contexts/toastContext/toastContext";
import { useTitlePage } from "../../../hooks/useTitlePage";
import { text } from "../../../shared/text/text";
import { UserApiType } from "../../../types/UserApiType";
import { catchError } from "../../../core/request/catchError";
import BasePage from "../../../components/common/basePage/BasePage";
import Button from "../../../components/common/button/Button";
import TableauUtilisateur from "../../../components/feature/utilisateur/listeUtilisateur/listeUtilisateur/TableauUtilisateur";

const ListeUtilisateur = (): JSX.Element => {
    const navigate = useNavigate();
    const { token, role } = useAuth().isAuthenticated;
    const { pushToast } = useToasts();
    const titlePage = useTitlePage(text.pages.listeUtilisateur.title);

    const [users, setUsers] = useState<UserApiType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {

        if (!token) return;

        setLoading(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/utilisateurs`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {

                catchError(data);

                setUsers(() => data);
            })
            .catch((error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setLoading(() => false);
            });
    }, [pushToast, token, role]);

    return (
        <BasePage title={titlePage}>
            <BoxListeUtilisateur>
                <BoxHeadingListeUtilisateur>
                    <HeadingListeUtilisateur>{text.pages.listeUtilisateur.title}</HeadingListeUtilisateur>

                    <Button
                        label={text.components.modalAjouterUnUtilisateur.labelBtnOpenModal}
                        loader={false}
                        type="button"
                        onClick={() => navigate('/utilisateur/ajouter')}
                    />
                </BoxHeadingListeUtilisateur>

                <TableauUtilisateur
                    users={users}
                    setUsers={setUsers}
                    loading={loading}
                />
            </BoxListeUtilisateur>
        </BasePage >
    );
};

export default ListeUtilisateur;