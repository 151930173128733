import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";

export const BoxPaginationTableUtilisateur = styled.div`
    width: 100%;
    height: 50px;
    padding: 0 15px; 
    margin-bottom: 60px;
    color: ${theme.color.colorLabel}; 
    background-color: ${theme.color.white};
    height: 50px; 
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: space-between;
`;

export const BoxleftTableUtilisateur = styled.div`
    width: 50%;
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: flex-start;
`;

export const BoxRightTableUtilisateur = styled.div`
    width: 210px;
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: space-between;
`;

export const BtnIconArrow = styled.button`
    width: 25px; 
    height: 25px; 
    background: none;
    border: none;
    border-radius: 50%; 
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%); 
    display: flex; 
    align-items: center; 
    justify-content: center;
    color: ${theme.color.primary};
    transition: background-color 0.1s, color 0.1s, scale 0.1s;
    &:hover{
        cursor: pointer;
        background-color: ${theme.color.primary};
        color: ${theme.color.white};
    }
    &:active{
        scale: 0.9;
    }
    &:disabled{
        pointer-events: none;
        color: ${theme.color.disabled};
    }
`;