import { keyframes, styled } from "styled-components";
import { PortalStyleProps } from "./PortalType";
import { XCircle } from "react-bootstrap-icons";
import { theme } from "../../../theme/theme";

const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
    z-index: 10;
    width: 100vw;
    height: 100vh; 
    position: fixed; 
    top: 0; 
    left: 0; 
    background-color: #00000073; 
    display: flex; 
    align-items: center;
    justify-content: center;
    animation: ${opacity} 225ms forwards;
    @media screen and (${theme.screen.tablette}) {
      display: flex; 
      align-items: flex-start;
      justify-content: center;
    };
`;

export const ModalBody = styled.aside<PortalStyleProps>`
    background-color: ${theme.color.white};
    width: ${props => props.$width ? `${props.$width}` : `fit-content`};
    max-height: 95%;
    overflow: hidden;
    box-shadow: 3px 3px 5px #424242;
    padding: 25px 25px 0 25px;
    @media screen and (${theme.screen.tablette}) {
      margin-top: 100px;
    };
`;

export const BoxHeadingPortal = styled.div`
    width: 100%;
    display: flex; 
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
`;

export const PortalTitle = styled.h3<PortalStyleProps>`
    height: fit-content;
    font-size: ${props =>props.$fontSize ? `${props.$fontSize}` : theme.font.medium};
    color: ${theme.color.primary};
`;

export const CircleClosePortal = styled(XCircle)`
    font-size: ${theme.font.xxlarge};
    color: ${theme.color.primary};
    transition: opacity 0.1s;
    &:hover{
        cursor: pointer;
        opacity: 0.5;
        color: ${theme.color.success};
    }
`;