import { ThemeType } from "./themeType"

export const theme: ThemeType = {
    color: {
        primary: '#008483',
        secondary: '#f4f4f4',
        headerTable: '#ececec',
        grayBtn:  '#efefef',
        grayBorder: '#dadada',
        letterGray: '#777777',
        letterGreen: '#bbf0f0',
        colorLabel: '#8c9090',
        colorParagraphe: '#2c2c2c',
        white: '#FFFFFF',
        disabled: '#8c9090',
        success: '#1dbc9c',
        warning: '#ffca22', 
        alert: '#fa5056',
        info: '#636ca6',
        bgcDrawer: '#1d212d'
    },
    font: {
        xxsmall: '10px',
        xsmall: '12px',
        small: '14px',
        regular: '16px',
        medium: '18px',
        large: '20px',
        xlarge: '25px',
        xxlarge: '30px',
    },
    css: {
        radius: '5px',
        shadow: '0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)',
    },
    screen: {
        laptopL: 'max-width: 1440px',
        laptopS: 'max-width: 1366px',
        laptopXS: 'max-width: 1280px',
        tablette: 'max-width: 768px',
    }
};