import { format } from "date-fns";
import { BoxFieldTabSociete, BoxTabSociete } from "./TabSocieteStyle";
import { TabSocieteProps } from "./TabSocieteType";
import InputCustom from "../../../../../../common/inputCustom/InputCustom";
import { text } from "../../../../../../../shared/text/text";

const TabSociete = ({ indexSelected, user, erreur, isEdit, changeUser }: TabSocieteProps): JSX.Element | null => {
    if (indexSelected === '0') {
        return (
            <BoxTabSociete>
                <BoxFieldTabSociete>
                    <InputCustom
                        width="32"
                        label={text.pages.utilisateur.labelNomSociete}
                        id="nomSociete"
                        type="text"
                        name="nomSociete"
                        value={user.nomSociete}
                        onChange={changeUser}
                        disabled={true}
                        error={erreur.nomSociete}
                    />

                    <InputCustom
                        width="32"
                        label={text.pages.utilisateur.labelNomDirigeant}
                        id="nomDirigeant"
                        type="text"
                        name="nomDirigeant"
                        value={user.nomDirigeant}
                        onChange={changeUser}
                        disabled={true}
                        error={erreur.prenomDirigeant}
                    />

                    <InputCustom
                        width="32"
                        label={text.pages.utilisateur.labelPrenomDirigeant}
                        id="prenomDirigeant"
                        type="text"
                        name="prenomDirigeant"
                        value={user.prenomDirigeant}
                        onChange={changeUser}
                        disabled={true}
                        error={erreur.prenomDirigeant}
                    />
                </BoxFieldTabSociete>

                <BoxFieldTabSociete>
                    <InputCustom
                        width="32"
                        label={text.pages.utilisateur.labelEmail}
                        id="email"
                        type="email"
                        name="email"
                        value={user.email}
                        onChange={changeUser}
                        disabled={!isEdit}
                        error={erreur.email}
                    />

                    <InputCustom
                        width="32"
                        label={text.pages.utilisateur.labelTelephone}
                        id="telephone"
                        type="tel"
                        name="telephone"
                        value={user.telephone}
                        onChange={changeUser}
                        disabled={!isEdit}
                        error={erreur.telephone}
                    />
                </BoxFieldTabSociete>

                <BoxFieldTabSociete>
                    <InputCustom
                        width="23.5"
                        label={text.pages.utilisateur.labelSiret}
                        id="siret"
                        type="text"
                        name="siret"
                        value={user.siret}
                        onChange={changeUser}
                        disabled={!isEdit}
                        error={erreur.siret}
                    />

                    <InputCustom
                        width="23.5"
                        label={text.pages.utilisateur.labelNumTva}
                        id="numTva"
                        type="text"
                        name="numTva"
                        value={user.numTva}
                        onChange={changeUser}
                        disabled={!isEdit}
                        error={erreur.numTva}
                    />

                    <InputCustom
                        width="23.5"
                        label={text.pages.utilisateur.labelCapital}
                        id="capital"
                        type="text"
                        name="capital"
                        value={user.capital}
                        onChange={changeUser}
                        disabled={!isEdit}
                        error={erreur.capital}
                    />

                    <InputCustom
                        width="23.5"
                        label={text.pages.utilisateur.labelTauxHoraireHt}
                        id="tauxHoraireHt"
                        type="text"
                        name="tauxHoraireHt"
                        value={user.tauxHoraireHt}
                        onChange={changeUser}
                        disabled={!isEdit}
                        error={erreur.tauxHoraireHt}
                    />
                </BoxFieldTabSociete>

                <BoxFieldTabSociete>
                    <InputCustom
                        label={text.pages.utilisateur.labelDateEditionKbis}
                        id="dateEditionKbis"
                        type="date"
                        name="dateEditionKbis"
                        value={format(new Date(user.dateEditionKbis), 'yyyy-MM-dd')}
                        onChange={changeUser}
                        disabled={true}
                        error={erreur.dateEditionKbis}
                    />

                    <InputCustom
                        label={text.pages.utilisateur.labelDateExpireKbis}
                        id="dateExpireKbis"
                        type="date"
                        name="dateExpireKbis"
                        value={format(new Date(user.dateExpireKbis), 'yyyy-MM-dd')}
                        onChange={changeUser}
                        disabled={true}
                        error={erreur.dateExpireKbis}
                    />
                </BoxFieldTabSociete>
            </BoxTabSociete>
        );
    };

    return null;
};

export default TabSociete;