import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";

export type LigneTableStyleProps = {
    $width?: string,
    $templateGrid?: string,
    $lineName?: string,
};

export const LigneTableContainer = styled.div<LigneTableStyleProps>`
    width: 100%; 
    padding: 15px 0;
    background-color: ${theme.color.white};  
    display: grid;
    grid-template-columns: ${props=> props.$templateGrid};
    transition: background-color 0.2s, scale 0.2s;
    border-bottom: solid 2px #ececec;
`;

export const CelluleLigneTable = styled.div<LigneTableStyleProps>`
    grid-column-start: ${props => props.$lineName};
    text-align: center;
    font-size: ${theme.font.small};
`;

export const CelluleId = styled.span`
    background-color: ${theme.color.primary};
    color: ${theme.color.white};
    padding: 5px 10px;
    border-radius: 10px;
`; 

export const CelluleActionLigneTable = styled.div<LigneTableStyleProps>`
    grid-column-start: ${props => props.$lineName};
    text-align: center; 
    font-size: ${theme.font.regular};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around; 
`;