import Drawer from "../drawer/drawer/Drawer";
import { MainCustom } from "./BasePageStyle";
import { ChildrenType } from "./BasePageType";

const BasePage = ({ children }: ChildrenType): JSX.Element => {
    return (
        <MainCustom>
            <Drawer>
                {children}
            </Drawer>
        </MainCustom>
    );
};

export default BasePage;
