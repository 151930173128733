import { text } from "../../../../../../shared/text/text";
import Button from "../../../../../common/button/Button";
import { BoxBtnAction, SeparatoBtnAction } from "./TabActionStyle";
import { TabActionProps } from "./TabActionType";

export const TabAction = ({ isEdit, setIsEdit, loading, annule }: TabActionProps): JSX.Element => {
    if (isEdit) {
        return (
            <BoxBtnAction>
                <Button
                    type="button"
                    color="alert"
                    label={text.components.modaleProduit.labelBtnAnnuler}
                    loader={false}
                    disabled={false}
                    onClick={annule}
                />

                <SeparatoBtnAction />

                <Button
                    width="230px"
                    type="submit"
                    label={text.components.modaleProduit.labelBtnValider}
                    loader={loading}
                    disabled={loading}
                />
            </BoxBtnAction>
        );
    }

    return (
        <BoxBtnAction>
            <Button
                width="230px"
                type="button"
                label={text.components.modaleUtilisateur.labelBtnModifier}
                loader={false}
                disabled={false}
                onClick={() => setIsEdit((curr: boolean) => !curr)}
            />
        </BoxBtnAction>
    );
};