export class AddProduitError {
    name: string;
    tax: string;
    code: string;
    totalPriceHt: string;
    quantity: string;
    invoiceIntegration: string;

    constructor() {
        this.name = "";
        this.tax = "";
        this.code = "";
        this.totalPriceHt = "";
        this.quantity = "";
        this.invoiceIntegration = "";
    };
};