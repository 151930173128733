import { CheckboxPropsType } from "./CheckboxType";
import { BoxCheckbox, InputCheckbox, Label } from "./Checkboxstyle";

const Checkbox = ({ width, mb, reverse = false, label, id, name, checked,disabled, onChange }: CheckboxPropsType): JSX.Element => {
    return (
        <BoxCheckbox $width={width} $mb={mb} $reverse={reverse}>
            <Label htmlFor={id}>{label}</Label>
            <InputCheckbox
                id={id}
                type="checkbox"
                $reverse={reverse}
                name={name}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
        </BoxCheckbox>
    );
};

export default Checkbox;