export const NomDeSocieteToolContent = () => {
    return(
        <div style={{width: 'fit-content'}}>
            <p style={{color: 'white', fontSize: 12}}>Le nom de société et unique,</p>
            <p style={{color: 'white', fontSize: 12}}>il doit être composé de 40 caractères maximum</p>
        </div>
    );
};

export const PasswordToolContent = () => {
    return(
        <div style={{width: 'fit-content'}}>
            <p style={{color: 'white', fontSize: 12}}>Le mot de passe doit contenir 10 caractères au minimum don:</p>
            <p style={{color: 'white', fontSize: 12}}>- 1 caractère spécial (@,$,?...)</p>
            <p style={{color: 'white', fontSize: 12}}>- 1 majuscule</p>
            <p style={{color: 'white', fontSize: 12}}>- 1 minuscule</p>
            <p style={{color: 'white', fontSize: 12}}>- 1 chiffre</p>
        </div>
    );
};

export const ConfirmPasswordToolContent = () => {
    return(
        <div style={{width: 'fit-content'}}>
            <p style={{color: 'white', fontSize: 12}}>Le mot de passe de confirmation doit être identique au 1er mot de passe donné</p>
        </div>
    );
};

export const CapitalToolContent = () => {
    return(
        <div style={{width: 'fit-content'}}>
            <p style={{color: 'white', fontSize: 12}}>Le capital est composé uniquement de chiffre</p>
            <p style={{color: 'white', fontSize: 12}}>Exemple: 5000</p>
            <p style={{color: 'white', fontSize: 12}}>! ne pas ajouter le sigle €</p>
        </div>
    );
};

export const PhoneToolContent = () => {
    return(
        <div style={{width: 'fit-content'}}>
            <p style={{color: 'white', fontSize: 12}}>Le téléphone est composé uniquement de 10 chiffres</p>
            <p style={{color: 'white', fontSize: 12}}>Exemple: 0000000000</p>
            <p style={{color: 'white', fontSize: 12}}>! ne pas ajouter d'espace ou autre séparateur entre les chiffres</p>
        </div>
    );
};

export const TauxHoraireToolContent = () => {
    return(
        <div style={{width: 'fit-content'}}>
            <p style={{color: 'white', fontSize: 12}}>Le taux horaire HT est composé uniquement de chiffres</p>
            <p style={{color: 'white', fontSize: 12}}>Utilisez le . pour les nombres décimaux</p>
            <p style={{color: 'white', fontSize: 12}}>Exemple: 10.25</p>
            <p style={{color: 'white', fontSize: 12}}>! ne pas ajouter d'espace ou autre séparateur entre les chiffres</p>
            <p style={{color: 'white', fontSize: 12}}>! ne pas ajouter le sigle €</p>
            <p style={{color: 'white', fontSize: 12}}>! ne pas utiliser la virgule pour les nombres décimaux</p>
        </div>
    );
};







