import { styled } from "styled-components";
import { Search } from "react-bootstrap-icons";
import { InputSearchStyleProps } from "./InputSearchType";
import { theme } from "../../../theme/theme";

export const BoxInputSearch = styled.div<InputSearchStyleProps>`
    width: ${props => props.$width ? `${props.$width}%` : `100%`};
    display: flex; 
    flex-direction: row; 
    align-items: center;
    border-top: solid 2px #ececec;
    border-bottom: solid 2px #ececec;
    background-color: white;
    margin: 25px 0 0 0;
    padding: 5px 10px;
`;

export const IconSearch = styled(Search)`
    font-size: 20px;
    color: ${theme.color.letterGray};
`;

export const InputS = styled.input`
    width: 100%;
    padding: 5px 15px;
    font-family: Roboto-Regular;
    background-color: transparent;
    font-size: 16px;
    border-top: none;
    border-left: none;
    border-right: none;
    border: none;
    color: ${theme.color.letterGray};
    &: focus {
        outline: none;
    }
`;