import { keyframes, styled } from "styled-components";
import { ButtonStylePropsType } from "./ButtonType";
import { theme } from "../../../theme/theme";


const buttonBackGroundColor = (color: string) => {

  let colorBase: string = theme.color.primary;

  switch (color) {
    case "regular":
      colorBase = theme.color.primary;
      break;
    case "info":
      colorBase = theme.color.info;
      break;
    case "warning":
      colorBase = theme.color.warning;
      break;
    case "alert":
      colorBase = theme.color.alert;
      break;
    case "success":
      colorBase = theme.color.success;
      break;
  };

  return `
    background-color: ${colorBase};
    border: solid 1px ${colorBase};
  `;
};

export const ButtonCustom = styled.button<ButtonStylePropsType>`
    width:  ${props => props.$width ? `${props.$width}` : `fit-content`};
    height:  38px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${theme.color.white};
    ${props => props.$color ?
    buttonBackGroundColor(props.$color)
    :
    `background-color: ${theme.color.primary}; 
     border: solid 1px ${theme.color.primary};`}
  
    border-radius: ${theme.css.radius};

    ${props => props.$loader && `pointer-events: none;`}
    ${props => props.disabled &&
    `
        background-color: ${theme.color.disabled};
        color: ${theme.color.white};
        border: solid 1px ${theme.color.disabled};
        pointer-events: none;
        opacity: 0.5;
       `
  }
     &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
    &:active{
        cursor: pointer;
        scale: 0.99;
    }
`;

export const prixClipFix = keyframes`
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;

export const rotate = keyframes`
  100% {transform: rotate(360deg)}
`;

export const Loader = styled.span`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  animation: ${rotate} 1s linear infinite;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 2px solid #FFF;
    animation: ${prixClipFix} 2s linear infinite ;
  }
`;

export const BoxIconLAbel = styled.div`
  width: 100%;
  display: flex; 
  flex-direction: row; 
  align-items: center;
  justify-content: center;
`;

