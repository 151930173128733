
import { z } from "zod";
import { UpdateUserError } from "./UpdateUserError";
import { messageNbr, messageStr, regexEmail } from "../../../../shared/regex/regex";

export const UpdateUserSchema = z.object({
    id: z.number(messageNbr).min(1),
    nomSociete: z.string(messageStr),
    email: z.string(messageStr).regex(regexEmail, { message: 'Le format de l\'adresse e-mail est invalide' }),
    nomDirigeant: z.string(messageStr).min(1, {message: "Le nom est obligatoire"}),
    prenomDirigeant: z.string(messageStr).min(1, {message: "Le prénom est obligatoire"}),
    creditAbonnement: z.number(messageStr).min(1, {message: "Les crédits sont obligatoire"}),
    creditConsoDuMois: z.number(messageStr),
    creditConsoGlobal: z.number(messageStr),
    createdAt: z.string(messageStr),
    updateAt: z.string(messageStr),
    siret: z.string(messageStr).min(1, {message: "Le siret est obligatoire"}),
    numTva: z.string(messageStr).min(1, {message: "Le numéro de TVA est obligatoire"}),
    capital: z.string(messageStr).min(1, {message: "Le capital est obligatoire"}),
    telephone: z.string(messageStr).min(1, {message: "Le téléphone est obligatoire"}),
    dateEditionKbis: z.string(messageStr).min(1, {message: "La date d'édition est obligatoire"}),
    dateExpireKbis: z.string(messageStr),
    urlVosFactures:  z.string(messageStr).url({message: "L'URL est obligatoire"}),
    apiKeyVosFactures:  z.string(messageStr).min(1, {message: "L'API key est obligatoire"}),
    tauxHoraireHt: z.string(messageStr).min(1, {message: "Le taux horaire est obligatoire"})
});

export type UpdateUserType = z.infer<typeof UpdateUserSchema>;

export type ErreurUpdateUserType = {
    error: boolean,
    detail: UpdateUserError
};