
import { z } from "zod";
import { SendEmailError } from "./SendEmailError";
import { fileSchemaArray } from "../../../types/File";

export const SendEmailSchema = z.object({
    nom: z.string().min(1),
    from: z.string().min(1),
    to: z.string().min(1),
    objet: z.string().min(1),
    message: z.string().min(1),
    "pj[]": fileSchemaArray,
});

export type SendEmailType = z.infer<typeof SendEmailSchema>;

export type ErreurSendEmailType = {
    error: boolean,
    detail: SendEmailError
};