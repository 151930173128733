import { PropsWithChildren, createContext, useCallback, useContext, useRef } from "react";
import { ParamsToastRef } from "./toastContextType";
import ToastContainer from "../../components/common/toast/toastContainer/ToastContainer";

const defaultPush = (toast: ParamsToastRef) => { };

const defaultToastValue = {
    pushToastRef: { current: defaultPush }
};

export const ToastContext = createContext<any>(defaultToastValue);

const ToastProvider = ({ children }: PropsWithChildren): JSX.Element => {

    const pushToastRef = useRef<any>(defaultToastValue);

    return (
        <ToastContext.Provider value={{ pushToastRef }}>
            <ToastContainer />
            {children}
        </ToastContext.Provider>
    );
};

export default ToastProvider;

export function useToasts() {
    const { pushToastRef } = useContext(ToastContext);

    return { 
        pushToast: useCallback((toast: ParamsToastRef) => {
            pushToastRef.current(toast)
        }, [pushToastRef])
    };
};