import { ChangeEvent, useState } from 'react';
import { InputFilePropsType } from './InputFileType';
import { BoxIconLabel, BoxInputFile, CameraCustom, ContainerFile, InputFileCustom, LabelInputFile, NamePieceJointe } from './InputFileStyle';
import { X } from 'react-bootstrap-icons';

const InputFile = ({ label, name }: InputFilePropsType): JSX.Element => {

    const [colorBorder, setcolorBorder] = useState<string>('#e0e0e0');
    const [colorLabel, setcolorLabel] = useState<string>('#616161');

    const [nameDoc, setNameDoc] = useState<string[]>([]);

    const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {

        let poidTotal: number = 0;

        if (e.target.files?.length) {

            [...e.target.files].forEach(element => {

                poidTotal = poidTotal + element.size;

                if (element.size >= 67000000) {
                    setcolorBorder('red');
                    setcolorLabel('red');
                    return;
                };

                setNameDoc((v: string[]) => [...v, element.name]);
            });
        };

        if (poidTotal >= 6700000) {
            setcolorBorder('red');
            setcolorLabel('red');
            return;
        };
    };

    const reset = () => {
        setNameDoc(() => []);
        setcolorBorder('#e0e0e0');
        setcolorLabel('#616161');
    };

    return (
        <ContainerFile>
            <BoxInputFile $color={colorBorder}>
                <BoxIconLabel>
                    {
                        nameDoc.length ?
                            nameDoc.length && nameDoc.map((N) => (
                                <NamePieceJointe key={N}>{N}</NamePieceJointe>
                            )) :

                            <>
                                <CameraCustom $colorLabel={colorLabel} />
                                <LabelInputFile>{label}</LabelInputFile>
                            </>
                    }
                </BoxIconLabel>

                <InputFileCustom name={name} onChange={onChangeFile} type="file" multiple />
            </BoxInputFile>

            <div style={{ width: 15 }}></div>


            {nameDoc.length ? <X size={25} color='#008483' onClick={reset} /> : null}
        </ContainerFile>
    );
};

export default InputFile;