import { styled } from "styled-components";
import { theme } from "../../../../../theme/theme";

export const ContainerDonneeCompte = styled.div`
    padding: 25px 0 0 0;
    width: 100%;
    height: 105px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    border-top: solid 1px ${theme.color.headerTable};
    border-bottom: solid 1px ${theme.color.headerTable};
`;

export const BoxDonneeCompte = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const HeadingCardDonneeCompte = styled.h4`
    color: ${theme.color.primary};
    font-size: ${theme.font.regular};
`;

export const BoxparagDonneeCompte = styled.div`
    width: 100%;
    padding: 0 0 0 10px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const ParagLabelDonneeCompte = styled.p`
    width: 12%; 
    color: ${theme.color.primary};
`;

export const ParagValueDonneeCompte = styled.p`
    width: 88%; 
    color: ${theme.color.colorParagraphe};
`;