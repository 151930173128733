import { BoxSelectElementParPage, LabelSelectElementParPage, SelectElementParPageCustom } from "./SelectElementParPageStyle";
import { OptionSelectElementParPage, SelectElementParPageProps } from "./SelectElementParPageType";

export const elementParPage: OptionSelectElementParPage[] = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 30, value: 30 },
    { label: 50, value: 50 },
];

const SelectElementParPage = ({ label, id, value, onChange, disabled, invalid }: SelectElementParPageProps) => {
    return (
        <BoxSelectElementParPage>
            {label &&
                <BoxSelectElementParPage>
                    <LabelSelectElementParPage htmlFor={id}>{label}</LabelSelectElementParPage>
                </BoxSelectElementParPage>
            }
            <SelectElementParPageCustom
                id={id}
                name={id}
                value={value}
                onChange={onChange}
                disabled={disabled}
                $invalid={invalid}
            >
                {elementParPage.map((opt, index) => (
                    <option key={index} value={opt.value}>{opt.label}</option>
                ))}
            </SelectElementParPageCustom>
        </BoxSelectElementParPage>
    );
};

export default SelectElementParPage;