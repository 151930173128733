export class AddUserError {
    email: string;
    password: string;
    confirmPassword: string;
    nomSociete: string;
    siret: string;
    numTva: string;
    capital: string;
    telephone: string;
    nomDirigeant: string;
    prenomDirigeant: string;
    urlVosFactures: string;
    apiKeyVosFactures: string;
    tauxHoraireHt: string;
    dateEditionKbis: string;
    creditAbonnement: string;
    cni: string;
    kbis: string;
    cachet: string;
    rib: string;

    constructor() {
        this.email = "";
        this.password = "";
        this.confirmPassword = "";
        this.nomSociete = "";
        this.siret = "";
        this.numTva = "";
        this.capital = "";
        this.telephone = "";
        this.nomDirigeant = "";
        this.prenomDirigeant = "";
        this.urlVosFactures = "";
        this.apiKeyVosFactures = "";
        this.tauxHoraireHt = "";
        this.dateEditionKbis = "";
        this.creditAbonnement = "";
        this.cni = "";
        this.kbis = "";
        this.cachet = "";
        this.rib = "";
    };
};