export class AddAssuranceClass {
    nomSociete: string;
    service: string;
    adresse: string;
    complementAdresse: string;
    ville: string;
    codePostal: string;
    email: string;

    constructor() {
        this.nomSociete = '';
        this.service = '';
        this.adresse = '';
        this.complementAdresse = '';
        this.ville = '';
        this.codePostal = '';
        this.email = '';
    };
};