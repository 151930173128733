import { useAuth } from "../../../../../../../contexts/authContext/AuthContext";
import { ROLE_ADMIN } from "../../../../../../../shared/constants/Constatnts";
import { text } from "../../../../../../../shared/text/text";
import Button from "../../../../../../common/button/Button";
import AcitveCompteUtilisateur from "../../acitveCompteUtilisateur/AcitveCompteUtilisateur";
import { BoxBtnAction, BoxBtnCancelAndValide, SeparatoBtnAction } from "./TabActionStyle";
import { TabActionProps } from "./TabActionType";

export const TabAction = ({ user, setUsers, isEdit, setIsEdit, loading, annule }: TabActionProps): JSX.Element => {
    const { role } = useAuth().isAuthenticated;

    if (isEdit) {
        return (
            <BoxBtnAction $role={role}>

                {role === ROLE_ADMIN &&
                    <AcitveCompteUtilisateur
                        user={user}
                        setUsers={setUsers}
                    />
                }

                <BoxBtnCancelAndValide>
                    <Button
                        type="button"
                        color="alert"
                        label={text.components.modaleUtilisateur.labelBtnAnnuler}
                        loader={false}
                        disabled={false}
                        onClick={annule}
                    />

                    <SeparatoBtnAction />

                    <Button
                        width="230px"
                        type="submit"
                        label={text.components.modaleUtilisateur.labelBtnValider}
                        loader={loading}
                        disabled={loading}
                    />
                </BoxBtnCancelAndValide>
            </BoxBtnAction>
        );
    }

    return (
        <BoxBtnAction $role={role}>

            {role === ROLE_ADMIN &&
                <AcitveCompteUtilisateur
                    user={user}
                    setUsers={setUsers}
                />
            }

            <Button
                width="230px"
                type="button"
                label={text.components.modaleUtilisateur.labelBtnModifier}
                loader={false}
                disabled={false}
                onClick={() => setIsEdit((curr: boolean) => !curr)}
            />
        </BoxBtnAction>
    );
};