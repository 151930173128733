import { useState } from 'react';
import { LigneUtilisateurPropsType } from './LigneUtilisateurType';
import { format } from 'date-fns';
import ModaleUtilisateur from '../../modaleUtilisateur/ModaleUtilisateur';
import ModalEnvoyerUnEmail from '../../modalEnvoyerUnEmail/ModalEnvoyerUnEmail';
import { useAuth } from '../../../../../../contexts/authContext/AuthContext';
import { useToasts } from '../../../../../../contexts/toastContext/toastContext';
import { catchError } from '../../../../../../core/request/catchError';
import { UserApiSchema, UserApiType } from '../../../../../../types/UserApiType';
import ModalConfirmation from '../../../../../common/modalConfirmation/ModalConfirmation';
import { text } from '../../../../../../shared/text/text';
import { CelluleActionLigneTable, CelluleId, CelluleLigneTable, LigneTableContainer } from '../../../../../common/tableau/container/LigneTableContainer';
import { IconActiveCustom, IconCancelCustom, IconTrashCustom } from '../../../../../common/tableau/IconsTable/IconsTable';
import { templateGridUtilisateur } from '../TableauUtilisateur';
import { ROLE_ADMIN } from '../../../../../../shared/constants/Constatnts';

const LigneUtilisateur = ({ user, setUsers }: LigneUtilisateurPropsType): JSX.Element | null => {
    const { token, role } = useAuth().isAuthenticated;
    const { pushToast } = useToasts();

    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);

    const supprimerUnUtilisateur = async (id: number) => {

        if (!token) return;

        setLoadingConfirm(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/utilisateur/${id}`, {
            method: "DELETE",
            headers: { "Authorization": `Bearer ${token}` },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                catchError(data);

                pushToast({ content: data.message, type: "success", duration: 5 });
                setUsers((curr: UserApiType[]) => curr.filter(user => user.id !== id));
                setOpenConfirm((curr: boolean) => !curr);
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: "alert", duration: 5 });
            })
            .finally(() => {
                setLoadingConfirm(() => false);
            });
    };

    if (user && UserApiSchema.safeParse(user).success) {
        return (
            <LigneTableContainer $templateGrid={templateGridUtilisateur}>
                <CelluleLigneTable $lineName='id'><CelluleId>{user.id}</CelluleId></CelluleLigneTable>
                <CelluleLigneTable $lineName='email'>{user.email}</CelluleLigneTable>
                <CelluleLigneTable $lineName='nomSociete'>{user.nomSociete}</CelluleLigneTable>
                <CelluleLigneTable $lineName='nomDirigeant'>{user.nomDirigeant}</CelluleLigneTable>
                <CelluleLigneTable $lineName='dateCreation'>{format(new Date(user.createdAt), 'dd/MM/yyyy')}</CelluleLigneTable>
                <CelluleLigneTable $lineName='actif'>{user.isActif ? <IconActiveCustom /> : <IconCancelCustom />}</CelluleLigneTable>

                <CelluleActionLigneTable $lineName='action'>

                    <ModaleUtilisateur
                        setUsers={setUsers}
                        user={user}
                    />

                    {role === ROLE_ADMIN &&
                        <ModalEnvoyerUnEmail
                            user={user}
                        />
                    }

                    {role === ROLE_ADMIN &&
                        <IconTrashCustom
                            onClick={() => setOpenConfirm((curr: boolean) => !curr)}
                        />
                    }
                </CelluleActionLigneTable>

                <ModalConfirmation
                    label={text.components.ligneUtilisateur.labelModalConfirm}
                    complementLabel={user.nomSociete}
                    open={openConfirm}
                    setOpen={setOpenConfirm}
                    action={() => supprimerUnUtilisateur(user.id)}
                    loading={loadingConfirm}
                />
            </LigneTableContainer>
        );
    } else {
        return null;
    };
};

export default LigneUtilisateur;