export const formDataToObject = <T>(formData: FormData): T => {
    const obj: any = {};

    for (const [key, value] of formData.entries()) {
        if (value instanceof File) {
            if (!obj[key]) {
                obj[key] = [];
            };
            
            obj[key].push({
                name: value.name,
                lastModified: value.lastModified,
                size: value.size,
                type: value.type,
            });
        } else {
            obj[key] = value;
        };
    };

    return obj as T;
};