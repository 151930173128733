import { useCallback, useEffect, useState } from "react";
import Button from "../button/Button";
import Portal from "../portal/Portal";
import { BoxButtonSession, CountModalSession, ParagModalSession } from "./ModalSessionStyle";
import { useAuth } from "../../../contexts/authContext/AuthContext";
import { catchError } from "../../../core/request/catchError";
import { NAME_STORAGE, NAME_STORAGE_REFRESH } from "../../../shared/constants/Constatnts";
import { hasAuthenticated } from "../../../services/authApi";
import { RefreshTokenApi } from "../../../contexts/authContext/AuthContextType";
import { useToasts } from "../../../contexts/toastContext/toastContext";

const ModalSession = (): JSX.Element | null => {
    const { isAuthenticated, setIsAuthenticated, logout } = useAuth();
    const { pushToast } = useToasts();

    const [open, setOpen] = useState<boolean>(false);
    const [count, setCount] = useState<number>(30);
    const [loader, setLoader] = useState<boolean>(false);

    const fermerModalSession = useCallback(() => {
        logout();
        setIsAuthenticated(hasAuthenticated());
        setOpen(() => false);
        setCount(31);
    }, [logout, setIsAuthenticated]);

    useEffect(() => {
        count === 0 && fermerModalSession();
    }, [count, fermerModalSession]);

    useEffect(() => {
        const checkTokenExpiration = () => {
            if (isAuthenticated.exp && isAuthenticated.connected) {
                const timeLeft = (isAuthenticated.exp * 1000) - 30000;
                setOpen(() => timeLeft <= Date.now());
            };
        };

        const interval = setInterval(checkTokenExpiration, 1000);

        return () => clearInterval(interval);
    }, [isAuthenticated.exp, isAuthenticated.connected]);

    useEffect(() => {
        if (open) {
            const checkTokenExpiration = () => {
                setCount((curr: number) => curr - 1);
            };

            const interval = setInterval(checkTokenExpiration, 1000);

            return () => clearInterval(interval);
        };
    }, [open]);

    const refresToken = () => {

        setLoader(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/token/admin/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh_token: isAuthenticated.refresh_token }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data: RefreshTokenApi) => {

                catchError(data);

                sessionStorage.setItem(NAME_STORAGE, data.token);
                sessionStorage.setItem(NAME_STORAGE_REFRESH, data.refresh_token);

                setIsAuthenticated(hasAuthenticated());
                pushToast({ content: "Votre session a été prolongée", type: 'success', duration: 5 });
                setCount(31);

            })
            .catch(() => {
                setTimeout(() => {
                    logout();
                }, 1000);
            })
            .finally(() => {
                setLoader(() => false);
            });
    };

    if (open) {


        return (
            <Portal
                displayHead={false}
                title={""}
                open={open}
                width="28vw"
                height="fit-content"
            >
                <ParagModalSession>Votre session va expirer dans <CountModalSession>{count}</CountModalSession></ParagModalSession>

                <BoxButtonSession>
                    <Button
                        width="200px"
                        type="button"
                        color="alert"
                        label="Quitter l'application"
                        loader={false}
                        disabled={false}
                        onClick={fermerModalSession}
                    />

                    <Button
                        width="200px"
                        type="button"
                        label="Prolonger ma session"
                        loader={loader}
                        disabled={false}
                        onClick={refresToken}
                    />
                </BoxButtonSession>
            </Portal>
        );
    };

    return null;
};
export default ModalSession;