export class UpdateAssuranceError {
    id: string;
    nomSociete: string;
    service: string;
    adresse: string;
    complementAdresse: string;
    ville: string;
    codePostal: string;
    email: string;

    constructor() {
        this.id = "";
        this.nomSociete = "";
        this.service = "";
        this.adresse = "";
        this.complementAdresse = "";
        this.ville = "";
        this.codePostal = "";
        this.email = "";
    };
};