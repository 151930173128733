import { BoxLogo, FirstLetter, FirstLetterMin, H1, SecondSubtititle, TwoLetterMin } from "./LogoStyle";
import { LogoProps } from "./LogoType";

const Logo = ({ visible = true }: LogoProps) => {
    return visible ? (
        <BoxLogo $visible={visible}>
            <H1 $visible={visible}><FirstLetter $visible={visible}>P</FirstLetter>ro<FirstLetter $visible={visible}>F</FirstLetter>act</H1>
            <SecondSubtititle $visible={visible}>Administration</SecondSubtititle>
        </BoxLogo>
    ) : (
        <BoxLogo title="Logo Pro fact" >
            <H1>
                <FirstLetterMin>P</FirstLetterMin><TwoLetterMin>F</TwoLetterMin>
            </H1>
        </BoxLogo>
    );
};

export default Logo;