import { ChangeEvent, FormEvent, useState } from 'react';
import { useAuth } from '../../../../contexts/authContext/AuthContext';
import Portal from '../../../common/portal/Portal';
import { useToasts } from '../../../../contexts/toastContext/toastContext';
import { text } from '../../../../shared/text/text';
import { UpdateProduitError } from '../../../../core/validation/produit/updateProduitValidation/UpdateProduitError';
import InputCustom from '../../../common/inputCustom/InputCustom';
import SelectCustom from '../../../common/selectCustom/SelectCustom';
import { optionTax } from '../../../../shared/optionSelect/optionTax';
import Checkbox from '../../../common/checkbox/Checkbox';
import { BoxBtnActionAddProduit, BoxFieldTabAddProduit, BoxTabAddProduit, FormVoirAddProduit, SeparatoBtnActionAddProduit } from './ModaleAjouterProduitStyle';
import Button from '../../../common/button/Button';
import { ModaleAjouterProduitProps } from '../../../../core/validation/produit/addProduitValidation/addProduitValidationType';
import { addProduitValidation } from '../../../../core/validation/produit/addProduitValidation/addProduitValidation';
import { catchError } from '../../../../core/request/catchError';
import { AddProduitClass } from '../../../../shared/class/produit/addProduit/AddProduitClass';

const ModaleAjouterProduit = ({ setProduits }: ModaleAjouterProduitProps): JSX.Element | null => {
    const { token } = useAuth().isAuthenticated;
    const { pushToast } = useToasts();

    const [open, setOpen] = useState<boolean>(false);
    const [produit, setProduit] = useState<AddProduitClass>(new AddProduitClass());
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    const [erreur, setErreur] = useState<UpdateProduitError>(new UpdateProduitError());

    const closeModale = () => {
        setProduit(new AddProduitClass());
        setErreur(() => new UpdateProduitError());
        setOpen(() => false);
    };

    const handleChangeProduit = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        const { name, type, value } = e.target;

        setProduit((curr: AddProduitClass) => {
            if (e.target instanceof HTMLInputElement && type === "checkbox" && name === "invoiceIntegration") {
                const { checked } = e.target;
                return { ...curr, [name]: checked }
            };
            return { ...curr, [name]: value }
        });
    };

    const ajouterUnProduit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const isValide = addProduitValidation(produit);

        setErreur({ ...erreur, ...isValide.detail });

        if (isValide.error) return;

        if (!token) return;

        setLoadingUpdate(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/facture/produit`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(produit)
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                catchError(data);

                setProduits((curr: AddProduitClass[]) => [...curr, data]);
                pushToast({ content: text.components.modaleAjouterProduit.msgSuccessCreate, type: "success", duration: 5 });

                closeModale();
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: "alert", duration: 5 });
            })
            .finally(() => {
                setLoadingUpdate(() => false);
            });
    };

    return (
        <>
            <Button
                label={text.components.modaleAjouterProduit.labelBtnAjouter}
                loader={false}
                type="button"
                onClick={() => setOpen(() => true)}
            />

            <Portal
                displayHead={true}
                title={text.components.modaleAjouterProduit.titlePortal}
                open={open}
                height="fit-content"
                onClick={closeModale}
            >
                <FormVoirAddProduit onSubmit={ajouterUnProduit}>
                    <BoxTabAddProduit>
                        <BoxFieldTabAddProduit>
                            <InputCustom
                                width="49"
                                label={text.components.modaleAjouterProduit.labelNomProduit}
                                id="name"
                                type="text"
                                name="name"
                                value={produit.name}
                                onChange={handleChangeProduit}
                                error={erreur.name}
                            />

                            <InputCustom
                                width="49"
                                label={text.components.modaleAjouterProduit.labelCodeProduit}
                                id="code"
                                type="text"
                                name="code"
                                placeholder='exemple: PRP0000'
                                value={produit.code}
                                onChange={handleChangeProduit}
                                error={erreur.code}
                            />
                        </BoxFieldTabAddProduit>

                        <BoxFieldTabAddProduit>
                            <InputCustom
                                width="32"
                                label={text.components.modaleAjouterProduit.labelTarifHt}
                                id="totalPriceHt"
                                type="text"
                                name="totalPriceHt"
                                placeholder='exemple: 10.15'
                                value={produit.totalPriceHt}
                                onChange={handleChangeProduit}
                                error={erreur.totalPriceHt}
                                pattern="[0-9]+([.][0-9]{0,2})?"
                            />

                            <InputCustom
                                width="32"
                                label={text.components.modaleAjouterProduit.labelQuantity}
                                id="quantity"
                                type="number"
                                name="quantity"
                                value={produit.quantity}
                                onChange={handleChangeProduit}
                                error={erreur.quantity}
                            />

                            <SelectCustom
                                label={text.components.modaleAjouterProduit.labeltax}
                                id="tax"
                                value={produit.tax}
                                options={optionTax}
                                onChange={handleChangeProduit}
                                invalid={erreur.tax}
                            />
                        </BoxFieldTabAddProduit>

                        <BoxFieldTabAddProduit>
                            <Checkbox
                                label={text.components.modaleAjouterProduit.labelIntegration}
                                mb={20}
                                id="invoiceIntegration"
                                name="invoiceIntegration"
                                checked={produit.invoiceIntegration}
                                onChange={handleChangeProduit}
                                reverse={true}
                            />
                        </BoxFieldTabAddProduit>
                    </BoxTabAddProduit>

                    <BoxBtnActionAddProduit>
                        <Button
                            type="reset"
                            color="alert"
                            label={text.components.modaleProduit.labelBtnAnnuler}
                            loader={false}
                            disabled={false}
                            onClick={closeModale}
                        />

                        <SeparatoBtnActionAddProduit />

                        <Button
                            width="230px"
                            type="submit"
                            label={text.components.modaleAjouterProduit.labelBtnCreer}
                            loader={loadingUpdate}
                            disabled={loadingUpdate}
                        />
                    </BoxBtnActionAddProduit>
                </FormVoirAddProduit>
            </Portal>
        </>
    );
};

export default ModaleAjouterProduit;