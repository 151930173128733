import { ChangeEvent, FormEvent, useState } from 'react';
import { useAuth } from '../../../../contexts/authContext/AuthContext';
import Portal from '../../../common/portal/Portal';
import { useToasts } from '../../../../contexts/toastContext/toastContext';
import { text } from '../../../../shared/text/text';
import InputCustom from '../../../common/inputCustom/InputCustom';
import Button from '../../../common/button/Button';
import { catchError } from '../../../../core/request/catchError';
import { ModalAddAssuranceProps } from './ModaleAjouterAssuranceType';
import { AddAssuranceClass } from '../../../../shared/class/assurance/addAssurance/AddAssurance';
import { BoxBtnActionAddAssurance, BoxFieldTabAddAssurance, BoxTabAddAssurance, FormAddAssurance, SeparatoBtnActionAddAssurance } from './ModaleAjouterAssuranceStyle';
import { AddAssuranceError } from '../../../../core/validation/assurance/addAssuranceValidation/AddAssuranceError';
import { addAssuranceValidation } from '../../../../core/validation/assurance/addAssuranceValidation/addAssuranceValidation';

const ModaleAjouterAssurance = ({ setAssurances }: ModalAddAssuranceProps): JSX.Element | null => {
    const { token } = useAuth().isAuthenticated;
    const { pushToast } = useToasts();

    const [open, setOpen] = useState<boolean>(false);
    const [assurance, setAssurance] = useState<AddAssuranceClass>(new AddAssuranceClass());
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    const [erreur, setErreur] = useState<AddAssuranceError>(new AddAssuranceError());

    const closeModale = () => {
        setAssurance(new AddAssuranceClass());
        setErreur(() => new AddAssuranceError());
        setOpen(() => false);
    };

    const handleChangeAssurance = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        const { name, type, value } = e.target;

        setAssurance((curr: AddAssuranceClass) => {
            if (e.target instanceof HTMLInputElement && type === "checkbox" && name === "invoiceIntegration") {
                const { checked } = e.target;
                return { ...curr, [name]: checked }
            };
            return { ...curr, [name]: value }
        });
    };

    const ajouterUneAssurance = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const isValide = addAssuranceValidation(assurance);

        setErreur({ ...erreur, ...isValide.detail });

        if (isValide.error) return;

        if (!token) return;

        setLoadingUpdate(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/assurance/coordonnee`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(assurance)
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {

                catchError(data);

                setAssurances((curr: AddAssuranceClass[]) => [...curr, data]);
                pushToast({ content: text.components.modaleAjouterProduit.msgSuccessCreate, type: "success", duration: 5 });

                closeModale();
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: "alert", duration: 5 });
            })
            .finally(() => {
                setLoadingUpdate(() => false);
            });
    };

    return (
        <>
            <Button
                label={text.components.modaleAjouterAssurance.labelBtnAjouter}
                loader={false}
                type="button"
                onClick={() => setOpen(() => true)}
            />

            <Portal
                displayHead={true}
                title={text.components.modaleAjouterAssurance.titlePortal}
                open={open}
                height="fit-content"
                onClick={closeModale}
            >
                <FormAddAssurance onSubmit={ajouterUneAssurance}>
                    <BoxTabAddAssurance>
                        <BoxFieldTabAddAssurance>
                            <InputCustom
                                width="49"
                                label={text.components.modaleAjouterAssurance.labelNomSciete}
                                id="nomSociete"
                                type="text"
                                name="nomSociete"
                                value={assurance.nomSociete}
                                onChange={handleChangeAssurance}
                                error={erreur.nomSociete}
                            />

                            <InputCustom
                                width="49"
                                label={text.components.modaleAjouterAssurance.labelEmail}
                                id="email"
                                type="text"
                                name="email"
                                value={assurance.email}
                                onChange={handleChangeAssurance}
                                error={erreur.email}
                            />
                        </BoxFieldTabAddAssurance>

                        <BoxFieldTabAddAssurance>
                            <InputCustom
                                width="49"
                                label={text.components.modaleAjouterAssurance.labelService}
                                id="service"
                                type="text"
                                name="service"
                                value={assurance.service}
                                onChange={handleChangeAssurance}
                                error={erreur.service}
                            />

                            <InputCustom
                                width="49"
                                label={text.components.modaleAjouterAssurance.labelAdresse}
                                id="adresse"
                                type="text"
                                name="adresse"
                                value={assurance.adresse}
                                onChange={handleChangeAssurance}
                                error={erreur.adresse}
                            />
                        </BoxFieldTabAddAssurance>

                        <BoxFieldTabAddAssurance>
                            <InputCustom
                                width="32"
                                label={text.components.modaleAjouterAssurance.labelComplAdresse}
                                id="complementAdresse"
                                type="text"
                                name="complementAdresse"
                                value={assurance.complementAdresse}
                                onChange={handleChangeAssurance}
                                error={erreur.complementAdresse}
                            />

                            <InputCustom
                                width="32"
                                label={text.components.modaleAjouterAssurance.labelVille}
                                id="ville"
                                type="text"
                                name="ville"
                                value={assurance.ville}
                                onChange={handleChangeAssurance}
                                error={erreur.ville}
                            />

                            <InputCustom
                                width="32"
                                label={text.components.modaleAjouterAssurance.labelCodePostal}
                                id="codePostal"
                                type="text"
                                name="codePostal"
                                value={assurance.codePostal}
                                onChange={handleChangeAssurance}
                                error={erreur.codePostal}
                            />
                        </BoxFieldTabAddAssurance>
                    </BoxTabAddAssurance>

                    <BoxBtnActionAddAssurance>
                        <Button
                            type="reset"
                            color="alert"
                            label={text.components.modaleProduit.labelBtnAnnuler}
                            loader={false}
                            disabled={false}
                            onClick={closeModale}
                        />

                        <SeparatoBtnActionAddAssurance />

                        <Button
                            width="230px"
                            type="submit"
                            label={text.components.modaleAjouterProduit.labelBtnAjouter}
                            loader={loadingUpdate}
                            disabled={loadingUpdate}
                        />
                    </BoxBtnActionAddAssurance>
                </FormAddAssurance>
            </Portal>
        </>
    );
};

export default ModaleAjouterAssurance;