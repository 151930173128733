export class AddUserClass {
    email: string;
    password: string;
    confirmPassword: string;
    nomSociete: string;
    siret: string;
    numTva: string;
    capital: string;
    telephone: string;
    nomDirigeant: string;
    prenomDirigeant: string;
    urlVosFactures: string;
    apiKeyVosFactures: string;
    tauxHoraireHt: string;
    dateEditionKbis: string;
    creditAbonnement: string | number;

    constructor() {
        this.email = '';
        this.password = '';
        this.confirmPassword = '';
        this.nomSociete = '';
        this.siret = '';
        this.numTva = '';
        this.capital = '';
        this.telephone = '';
        this.nomDirigeant = '';
        this.prenomDirigeant = '';
        this.urlVosFactures = '';
        this.apiKeyVosFactures = '';
        this.tauxHoraireHt = '';
        this.dateEditionKbis = '';
        this.creditAbonnement = '';
    };

    convertCreditAbonnementToNumber(): void {
        if (typeof this.creditAbonnement === 'string') {
            const numberValue = parseFloat(this.creditAbonnement);
            if (!isNaN(numberValue)) {
                this.creditAbonnement = numberValue;
            } else {
                console.error('Conversion failed: creditAbonnement is not a valid number string.');
            };
        };
    };

    convertCreditAbonnementToString(): void {
        if (typeof this.creditAbonnement === 'number') {
            this.creditAbonnement = String(this.creditAbonnement);
        };
    };
};