import { format } from "date-fns";
import { BoxDonneeCompte, BoxparagDonneeCompte, ContainerDonneeCompte, HeadingCardDonneeCompte, ParagLabelDonneeCompte, ParagValueDonneeCompte } from "./DonneeCompteStyle";
import { text } from "../../../../../shared/text/text";
import { UserApiSchema, UserApiType } from "../../../../../types/UserApiType";

export type DonneeCompteProps = {
    user: UserApiType | null
};

const DonneeCompte = ({ user }: DonneeCompteProps): JSX.Element | null => {
    if (user && UserApiSchema.safeParse(user).success) {
        return (
            <ContainerDonneeCompte>
                <BoxDonneeCompte>
                    <HeadingCardDonneeCompte>{text.components.modaleUtilisateur.labelCreationCompte}</HeadingCardDonneeCompte>

                    <BoxparagDonneeCompte>
                        <ParagLabelDonneeCompte>{text.components.modaleUtilisateur.labelQuandCreationCompte}</ParagLabelDonneeCompte>
                        <ParagValueDonneeCompte>{format(new Date(user.createdAt), 'dd/MM/yyyy à HH:mm:ss')}</ParagValueDonneeCompte>
                    </BoxparagDonneeCompte>
                </BoxDonneeCompte>

                <BoxDonneeCompte>
                    <HeadingCardDonneeCompte>{text.components.modaleUtilisateur.labelmiseAjourCompte}</HeadingCardDonneeCompte>

                    <BoxparagDonneeCompte>
                        <ParagLabelDonneeCompte>{text.components.modaleUtilisateur.labeQuandlmiseAjourCompte}</ParagLabelDonneeCompte>
                        <ParagValueDonneeCompte>{format(new Date(user.updateAt), 'dd/MM/yyyy à HH:mm:ss')}</ParagValueDonneeCompte>
                    </BoxparagDonneeCompte>
                </BoxDonneeCompte>

            </ContainerDonneeCompte>
        );
    } else {
        return null;
    };
};

export default DonneeCompte;