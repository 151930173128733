import { useState } from 'react';
import { LigneProduitProps } from './LigneProduitType';
import { useAuth } from '../../../../../contexts/authContext/AuthContext';
import { useToasts } from '../../../../../contexts/toastContext/toastContext';
import { UserApiType } from '../../../../../types/UserApiType';
import { ProduitSchema } from '../../../../../pages/produit/ListeProduitType';
import ModalConfirmation from '../../../../common/modalConfirmation/ModalConfirmation';
import { text } from '../../../../../shared/text/text';
import ModaleProduit from '../../modaleProduit/ModaleProduit';
import { catchError } from '../../../../../core/request/catchError';
import { CelluleActionLigneTable, CelluleId, CelluleLigneTable, LigneTableContainer } from '../../../../common/tableau/container/LigneTableContainer';
import { IconActiveCustom, IconCancelCustom, IconTrashCustom } from '../../../../common/tableau/IconsTable/IconsTable';
import { templateGrid } from '../TableauProduit';

const LigneProduit = ({ produit, setProduits }: LigneProduitProps): JSX.Element | null => {
    const { token } = useAuth().isAuthenticated;
    const { pushToast } = useToasts();

    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);

    const supprimerUnUtilisateur = async (id: number) => {

        if (!token) return;

        setLoadingConfirm(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/facture/produit/${id}`, {
            method: "DELETE",
            headers: { "Authorization": `Bearer ${token}` },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                catchError(data);

                pushToast({ content: data.detail, type: "success", duration: 5 });
                setProduits((curr: UserApiType[]) => curr.filter(user => user.id !== id));
                setOpenConfirm((curr: boolean) => !curr);
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: "alert", duration: 5 });
            })
            .finally(() => {
                setLoadingConfirm(() => false);
            });
    };

    if (ProduitSchema.safeParse(produit).success) {
        return (
            <LigneTableContainer $templateGrid={templateGrid}>
                <CelluleLigneTable $lineName='id'><CelluleId>{produit.id}</CelluleId></CelluleLigneTable>
                <CelluleLigneTable $lineName='nom'>{produit.name}</CelluleLigneTable>
                <CelluleLigneTable $lineName='code'>{produit.code}</CelluleLigneTable>
                <CelluleLigneTable $lineName='priceHt'>{produit.totalPriceHt}€</CelluleLigneTable>
                <CelluleLigneTable $lineName='priceTtc'>{produit.totalPriceGross}€</CelluleLigneTable>
                <CelluleLigneTable $lineName='tva'>{produit.tax}%</CelluleLigneTable>
                <CelluleLigneTable $lineName='quantite'>{produit.quantity}</CelluleLigneTable>
                <CelluleLigneTable $lineName='inclus'>{produit.invoiceIntegration === true ? <IconActiveCustom /> : <IconCancelCustom />}</CelluleLigneTable>
                <CelluleActionLigneTable $lineName='action'>

                    <ModaleProduit
                        setProduits={setProduits}
                        produit={produit}
                    />

                    <IconTrashCustom
                        onClick={() => setOpenConfirm((curr: boolean) => !curr)}
                    />
                </CelluleActionLigneTable>

                <ModalConfirmation
                    label={text.components.ligneProduit.labelModalConfirm}
                    complementLabel={produit.name}
                    open={openConfirm}
                    setOpen={setOpenConfirm}
                    action={() => supprimerUnUtilisateur(produit.id)}
                    loading={loadingConfirm}
                />
            </LigneTableContainer>
        );
    } else {
        return null;
    };
};

export default LigneProduit;