import { useState } from 'react';
import { TableauProduitProps } from './TableauProduitType';
import { TableProduitSchema } from '../../../../pages/produit/ListeProduitType';
import LigneProduit from './ligneProduit/LigneProduit';
import InputSearch from '../../../common/inputSearch/InputSearch';
import LoaderTable from '../../../common/loaderTable/LoaderTable';
import { BoxTable, CelluleTableHead, TableBody, TableContainer, TableHead } from '../../../common/tableau/container/TableContainer';
import Pagination from '../../../common/tableau/pagination/Pagination';

const theadData = [
    { label: 'Id', lineName: 'id' },
    { label: 'Nom', lineName: 'nom' },
    { label: 'Code', lineName: 'code' },
    { label: 'prix HT', lineName: 'priceHt' },
    { label: 'prix TTC', lineName: 'priceTtc' },
    { label: 'TVA', lineName: 'tva' },
    { label: 'Quantité', lineName: 'quantite' },
    { label: 'Auto inclus', lineName: 'inclus' },
    { label: 'Action', lineName: 'action' }
];
export const templateGrid ='[id] 0.2fr [nom] 1.5fr [code] 0.5fr [priceHt] 1fr [priceTtc] 1fr [tva] 0.6fr [quantite] 0.6fr [inclus] 0.6fr [action] 0.5fr'

const TableauProduit = ({ produits, setProduits, loading = false }: TableauProduitProps): JSX.Element | null => {
    const [fieldSearch, setFieldSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    if (loading) return <LoaderTable isLoading={loading} />;

    if (!loading && produits && produits.length && TableProduitSchema.safeParse(produits).success) {
        return (
            <TableContainer>
                <InputSearch
                    width={100}
                    placeholder="Rechercher..."
                    id="rechercheUser"
                    type="text"
                    value={fieldSearch}
                    onChange={(e) => setFieldSearch(e.target.value)}
                />

                <BoxTable>
                    <TableHead $templateGrid={templateGrid}>
                        {theadData.map((head, index) => (
                            <CelluleTableHead
                                $lineName={head.lineName}
                                key={head.label + index}
                            >
                                {head.label}
                            </CelluleTableHead>
                        ))}
                    </TableHead>

                    <TableBody>
                        {produits.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((val) => {
                            return val.code.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase()) ||
                                val.name.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase()) ||
                                val.createdAt.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase())
                        }).map((produit, index) => (
                            <LigneProduit key={index} produit={produit} setProduits={setProduits} />
                        ))}

                    </TableBody>
                </BoxTable>

                <Pagination
                    elementParPage={rowsPerPage}
                    setElementParPage={setRowsPerPage}
                    page={page}
                    totalpages={Math.ceil(produits.length / rowsPerPage)}
                    onClickChangePage={handleChangePage}
                />
            </TableContainer>
        );
    } else {
        return null;
    }
};

export default TableauProduit;