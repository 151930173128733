import { z } from "zod";

export const ProduitSchema = z.object({
    id: z.number(),
    name: z.string(),
    tax: z.string(),
    code: z.string(),
    totalPriceGross: z.string(),
    totalPriceHt:z.string(),
    quantity:z.string(),
    createdAt:z.string(),
    updateAt:z.string(),
    invoiceIntegration: z.boolean()
});

export const TableProduitSchema = ProduitSchema.array();

const PaginationSchema = z.object({
    currentPage: z.number(),
    limit: z.number(),
    param: z.string(),
    paramValue: z.string(),
    totalPages: z.number(),
    totalItems: z.number()
});

export const ProduitResultSchema = z.object({
    data: ProduitSchema.array(),
    paramPagination : PaginationSchema
});

export type ProduitApiType = z.infer<typeof ProduitSchema>;
export type ParamPagination = z.infer<typeof PaginationSchema>;
export type ProduitResultType = z.infer<typeof PaginationSchema>;