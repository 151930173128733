import { FormEvent, useEffect, useState } from "react";
import {  useAuth } from "../../contexts/authContext/AuthContext";
import { BoxBtnLogin, BoxInputLogin, Form, HeadingForm, Main } from "./LoginStyle";
import { useNavigate } from "react-router-dom";
import { LoginBody, LoginBodySchema, LoginResponse } from "./LoginType";
import Logo from "../../components/common/logo/Logo";
import Button from "../../components/common/button/Button";
import { text } from "../../shared/text/text";
import { NAME_STORAGE, NAME_STORAGE_REFRESH } from "../../shared/constants/Constatnts";
import InputCustom from "../../components/common/inputCustom/InputCustom";
import { useToasts } from "../../contexts/toastContext/toastContext";
import { LoginResponseSchema } from "../../types/UserApiType";
import { catchError } from "../../core/request/catchError";
import { validStorage } from "../../services/validStorage";

export default function Home() {

  const navigate = useNavigate();
  const { pushToast } = useToasts();
  const { setIsAuthenticated, isAuthenticated } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    isAuthenticated.connected && navigate('/');
  }, [isAuthenticated.connected, navigate]);

  const onSubmitConnexion = async (e: FormEvent) => {
    e.preventDefault();

    const login: LoginBody = {
      email: email.trim(),
      password: password.trim()
    };

    if (!LoginBodySchema.safeParse(login).success) {
      pushToast({ content: text.pages.login.msgErrCredencials, type: 'alert', duration: 5 });
      setLoading(() => false);
      return;
    };

    setLoading(() => true);

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/log/admin`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(login)
      })
      .then((response) => {
        return response.json();
      })
      .then((data: LoginResponse) => {

        catchError(data);

        if (!LoginResponseSchema.safeParse(data).success) {
          pushToast({ content: "Une erreur et survenue durant l'authentification veuillez réessayer", type: 'alert', duration: 5 });
          return;
        };

        sessionStorage.setItem(NAME_STORAGE, data.token);
        sessionStorage.setItem(NAME_STORAGE_REFRESH, data.refresh_token);

        setIsAuthenticated(validStorage(data.token, data.refresh_token));

        navigate('/');
      })
      .catch((error) => {
        pushToast({ content: error.message, type: 'alert', duration: 5 });
      })
      .finally(() => {
        setEmail(() => '');
        setPassword(() => '');
        setLoading(() => false);
      });
  };

  return (
    <Main>
      <Form onSubmit={onSubmitConnexion}>

        <HeadingForm>{text.pages.login.subtitle}</HeadingForm>

        <Logo />

        <BoxInputLogin>
          <InputCustom
            id="adresseEmail"
            type="text"
            name="adresseEmail"
            placeholder={text.pages.login.placeHolderEmail}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error=""
          />

          <InputCustom
            id="password"
            type="password"
            name="password"
            placeholder={text.pages.login.placeHolderPassword}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error=""
          />
        </BoxInputLogin>

        {/*<LinkPassword to="/" replace>{text.pages.login.mdpOublie}</LinkPassword>*/}

        <BoxBtnLogin>
          <Button
            width="100%"
            type="submit"
            label={text.pages.login.labelBtnConnexion}
            loader={loading}
            disabled={false}
          />
        </BoxBtnLogin>
      </Form>
    </Main>

  );
};
