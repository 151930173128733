import { InfoCircle } from 'react-bootstrap-icons';
import { theme } from '../../../theme/theme';
import { Tooltip } from 'react-tooltip';
import { TooltipsPropsType } from './TooltipsType';

import './Tooltips.css';

const Tooltips = ({ content, id }: TooltipsPropsType): JSX.Element => {
    return (
        <div id={id} className="boxIconTooltips">
            <InfoCircle color={theme.color.primary} size={theme.font.small} />

            <Tooltip anchorSelect={`#${id}`} className="boxContentTooltips">
                {content}
            </Tooltip>
        </div>
    );
};

export default Tooltips;