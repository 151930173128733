import styled from "styled-components";
import { Paperclip } from 'react-bootstrap-icons';
import { theme } from "../../../theme/theme";

export type InputFileStyleProps = {
    $color?: string,
    $colorLabel?: string
};

export const ContainerFile = styled.div`
    max-width: 100%; 
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    flex-wrap: nowrap;
`;

export const BoxInputFile = styled.div<InputFileStyleProps>`
    position: relative; 
    width: max-content;
    max-width: 92%;
    padding: 5px 10px; 
    background-color: white; 
    border-radius: 4px;
    &:hover {
        cursor: pointer;
    }
`;

export const BoxIconLabel = styled.div`
    width: 100%;
    background-color: transparent;
    display: flex; 
    flex-direction: row; 
    align-items: center;
    justify-content: flex-start; 
    flex-wrap: wrap;
    border-radius: 4px;
    &:hover {
        cursor: pointer;
    }
`;

export const CameraCustom = styled(Paperclip) <InputFileStyleProps>`
    color: ${props => props.$colorLabel};
    font-size: 20px;
    rotate: 45deg;
    color: ${theme.color.primary};
    &:hover {
        cursor: pointer;
    }
`;

export const LabelInputFile = styled.label<InputFileStyleProps>`
    z-index: 0;
    width: max-content;
    height: 100%;
    color: ${props => props.$colorLabel};
    font-size: ${theme.font.small};
    margin-left: 5px;
    &:hover {
        cursor: pointer;
    }
`;

export const InputFileCustom = styled.input`
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    border-radius: 4px;
    &:hover {
        cursor: pointer;
    }
`;

export const NamePieceJointe = styled.p`
    font-size: ${theme.font.xxsmall}; 
    margin: 0 5px;
`;





