import { useState } from 'react';
import { TableauAssuranceProps } from '../listeAssurance/TableauAssuranceType';
import LoaderTable from '../../../common/loaderTable/LoaderTable';
import { AssuranceArraySchema } from '../../../../pages/assurance/listeAssurance/ListeAssuranceType';
import InputSearch from '../../../common/inputSearch/InputSearch';
import LigneAssurance from './ligneAssurance/LigneAssurance';
import Pagination from '../../../common/tableau/pagination/Pagination';
import { BoxTable, CelluleTableHead, TableBody, TableContainer, TableHead } from '../../../common/tableau/container/TableContainer';

const theadData = [
    { label: 'Id', lineName: 'id' },
    { label: 'Email', lineName: 'email' },
    { label: 'Nom société', lineName: 'nomSociete' },
    { label: 'Ville', lineName: 'ville' },
    { label: 'action', lineName: 'action' }
];

export const templateGridAssurance='[id] 0.2fr [email] 1fr [nomSociete] 1fr [ville] 1fr [action] 0.3fr'

const TableauAssurance = ({ assurances, setAssurances, loading = false }: TableauAssuranceProps): JSX.Element | null => {
    const [fieldSearch, setFieldSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    if (loading) return <LoaderTable isLoading={loading} />;

    if (!loading && assurances && assurances.length && AssuranceArraySchema.safeParse(assurances).success) {
        return (
            <TableContainer>
                <InputSearch
                    width={100}
                    placeholder="Rechercher..."
                    id="rechercheUser"
                    type="text"
                    value={fieldSearch}
                    onChange={(e) => setFieldSearch(e.target.value)}
                />
                <BoxTable>
                    <TableHead $templateGrid={templateGridAssurance}>
                        {theadData.map((head, index) => (
                            <CelluleTableHead
                                $lineName={head.lineName}
                                key={head.label + index}
                            >
                                {head.label}
                            </CelluleTableHead>
                        ))}
                    </TableHead>

                    <TableBody>
                        {assurances.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((val) => {
                            return val.email.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase()) ||
                                val.nomSociete.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase()) ||
                                val.ville.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase()) ||
                                val.codePostal.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase())
                        }).map((assurance, index) => (
                            <LigneAssurance key={index} assurance={assurance} setAssurances={setAssurances} />
                        ))}
                    </TableBody>
                </BoxTable>

                <Pagination
                    elementParPage={rowsPerPage}
                    setElementParPage={setRowsPerPage}
                    page={page}
                    totalpages={Math.ceil(assurances.length / rowsPerPage)}
                    onClickChangePage={handleChangePage}
                />
            </TableContainer>
        );
    } else {
        return null;
    }
};

export default TableauAssurance;