import { BoxLoading, LoaderT } from "./LoaderTableStyle";
import { LoaderTableProps } from "./LoaderTableType";

const LoaderTable = ({ isLoading = false }: LoaderTableProps): JSX.Element | null => {
    if (isLoading) {
        return (
            <BoxLoading>
                <LoaderT />
            </BoxLoading>
        );
    };

    return null;
};

export default LoaderTable;