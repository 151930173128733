import Button from "../button/Button";
import Portal from "../portal/Portal";
import { BoxButtonConfirm, ParagModalConfirme, SpanModalConfirme } from "./ModalConfirmationStyle";
import { ModalConfirmationProps } from "./ModalConfirmationType";

const ModalConfirmation = ({ label, complementLabel, open, setOpen, action, loading}: ModalConfirmationProps) => {

    return (
        <Portal
            displayHead={false}
            title={""}
            open={open}
            width="30vw"
            height="fit-content"
            onClick={() => setOpen(() => false)}
        >

            <ParagModalConfirme>{label}</ParagModalConfirme>
            
            <SpanModalConfirme>{complementLabel}</SpanModalConfirme>

            <BoxButtonConfirm>
                <Button
                    width="48%"
                    type="button"
                    color="alert"
                    label="Annuler"
                    loader={false}
                    disabled={false}
                    onClick={() => setOpen(() => false)}
                />

                <Button
                    width="48%"
                    type="button"
                    label="Je confirme"
                    loader={loading}
                    disabled={loading}
                    onClick={action}
                />
            </BoxButtonConfirm>
        </Portal>
    );
};

export default ModalConfirmation;