import { useState } from "react";
import { useAuth } from "../../../../../../contexts/authContext/AuthContext";
import { useToasts } from "../../../../../../contexts/toastContext/toastContext";
import { AcitveCompteUtilisateurProps } from "./AcitveCompteUtilisateurType";
import { catchError } from "../../../../../../core/request/catchError";
import { UserApiType } from "../../../../../../types/UserApiType";
import Button from "../../../../../common/button/Button";
import { text } from "../../../../../../shared/text/text";

const AcitveCompteUtilisateur = ({ user, setUsers }: AcitveCompteUtilisateurProps): JSX.Element => {

    const { token } = useAuth().isAuthenticated;
    const { pushToast } = useToasts();

    const [loading, setLoading] = useState<boolean>(false);

    const handleAction = (action: 'desactivate' | 'activate'): void => {

        if (!token || !user.id) return;

        setLoading(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/utilisateur/${action}/${user.id}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {

                catchError(data);

                setUsers((curr: UserApiType[]) => curr.map((item) => item.id === user.id ? {...user, isActif: !user.isActif} : item ));
                pushToast({ content: data.message, type: 'success', duration: 5 });
            })
            .catch((error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setLoading(() => false);
            });
    };

    if (user && user.isActif === true) {
        return (
            <>
                <Button
                    width="230px"
                    type="button"
                    color="alert"
                    label={text.components.modaleUtilisateur.labelBtnDesactive}
                    loader={loading}
                    disabled={loading}
                    onClick={() => handleAction('desactivate')}
                />
            </>
        );
    } else {
        return (
            <>
                <Button
                    width="230px"
                    type="button"
                    label={text.components.modaleUtilisateur.labelBtnActive}
                    loader={loading}
                    disabled={loading}
                    onClick={() => handleAction('activate')}
                />
            </>
        );
    }
};

export default AcitveCompteUtilisateur;