import { styled } from 'styled-components';
import { theme } from '../../../../../theme/theme';

export const FormEnvoyerUnEmail = styled.form`
    width: 800px;
    height: fit-content;
    max-height: 90vh;
    padding-bottom: 25px;
    @media screen and (${theme.screen.tablette}) {
        width: 90vw;
        padding-top: 20px;
    };
`;

export const BoxEmetteurEnvoyerUnEmail = styled.div`
    width: 100%;
    height: 65px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 0;
    justify-content: space-between;
    border-top: solid 1px ${theme.color.headerTable};
    border-bottom: solid 1px ${theme.color.headerTable};
`;

export const BoxEmetteurDestinatiare = styled.div`
    width: 100%;
    padding: 0 0 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;


export const ParagLabelAddressEmail = styled.p`
    font-size: ${theme.font.small}; 
    width: 5%;
    color: ${theme.color.primary}
`;

export const ParagValieAddressEmail = styled.p`
    color: ${theme.color.colorParagraphe};
    font-size: ${theme.font.small};
`;

export const ParagEmailItalic = styled.p`
    color: ${theme.color.colorParagraphe};
    font-size: ${theme.font.small};
    font-family: Montserrat-Italic;
    margin-left: 15px;
    &:before{
        content: '<'
    }
    &:after{
        content: '>'
    }
`;

export const BoxFieldAddressEmail = styled.div`
    width: 100%;
    height: 265px;
    margin: 25px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`;

export const BoxPjAddressEmail = styled.div`
    width: 100%;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

export const BoxBtnAddressEmail = styled.div`
    width: 100%;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    border-top: solid 1px ${theme.color.headerTable};
`;

export const SeparatorBtnAddressEmail = styled.div`
    width: 30px;
`;











