import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const BoxListeAssurance = styled.div`
   width: 70%;
   height: calc(100% - 100px);
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start
   @media screen and (${theme.screen.laptopL}) {
      width: 95%;
      min-height: 100vh;
   };

   @media screen and (${theme.screen.tablette}) {
      width: 95%;
   };
`;

export const BoxHeadingListeAssurance = styled.div`
   width: 100%;
   height: 60px;
   margin: 15px 0 0 0;
   padding-bottom: 20px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   border-bottom: solid 2px ${theme.color.headerTable};
`;

export const HeadingListeAssurance = styled.h2`
   color: ${theme.color.primary};
   font-size: ${theme.font.xlarge};
`;
