export class SendEmailError {
    nom: string;
    from: string;
    to: string;
    objet: string;
    message: string;

    constructor() {
        this.nom = "";
        this.from = "";
        this.to = "";
        this.objet = "";
        this.message = "";
    };
};